import React, { useState } from 'react';
import { Card, Row, Col, Button, Table } from 'react-bootstrap';
import { ResponseResult, RouteParam } from '../../types';
import { request } from '../../services/Request';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function StudentsUpload() {

  const { id } = useParams<RouteParam>();

  interface Files extends Blob{
    name: string;
  }

  interface NewBadRegister {
    name: string;
    status: string;
    description: string;
    [key: string]: string;
  }
  const columns = [
    {
      dataField: 'name',
      text: 'Estudiante',
    },
    {
      dataField: 'status',
      text: 'Estatus',
    },
    {
      dataField: 'description',
      text: 'Descripción',
    },
  ];
  const [asigneedData, setAsigneedData] = useState<NewBadRegister[]>([]);
  const [selectedFiles, setFiles] = useState<Files[]>([]);
  const [showRegisterStatus, setShowRegisterStatus] = useState(false);

  const onDrop = (acceptedFiles: File[]) => {
    let files = selectedFiles;
    files = acceptedFiles;
    setFiles(files);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xlsx']
    }
  });

  const upload = async () => {
    if (selectedFiles.length > 0) {
      Swal.fire('Subir alumnos', 'Cargando...');
      Swal.showLoading();
      const data = new FormData();
      for (const file of selectedFiles) {
        data.append('files', file);
      }
      if (id === 'register') {
        request('/api/student/uploadStudents', {
          method: 'POST',
          file: true,
          body: data
        })
          .then((result: ResponseResult) => {
            Swal.close();
            if (result.data) {

              if (result.data.newBadRegisters) {
                setAsigneedData(result.data.newBadRegisters);

                Swal.fire('Error al subir alumnos', 'Existen problemas con algunos registros del archivo', 'error')
                  .then(() => {
                    setShowRegisterStatus(true);
                  })
              } else {
                Swal.fire('Subir alumnos', 'Los alumnos se subieron correctamente', 'success')
                  .then(() => {
                    window.location.href = "/students/list"
                  })
              }

            } else {
              Swal.fire('Subir alumnos', 'Ocurrió un error', 'error');
            }
          })
      }
      if (id === 'delete') {
        const response = await request('/api/student/deleteStudents', {
          method: 'POST',
          file: true,
          body: data
        })
        Swal.close();
        if (response.status && response.status === 200) {
          Swal.fire('Borrar alumnos', 'Los alumnos se borraron correctamente', 'success')
            .then(() => {
              window.location.href = "/students/list"
            })
        }  else if(response.status === 400) {
          Swal.fire('Borrar alumnos', 'Existen curps vacías, por favor revisa el archivo', 'error');
        } else {
          Swal.fire('Borrar alumnos', 'Ocurrió un errar al borrar los alumnos', 'error');
        }

      }
    }}

    return (
      <div id="upload" className="content">
        <Row>
          <Col xs={24} md={{ span: 8, offset: 2 }}>
            <Card className="mt-5 p-3">
              <Card.Body>
                <h3>{id === 'register' ? 'Subir alumnos por excel' : 'Borrar alumnos por excel'}</h3>
                <a href="/PlantillaAlumnos2.xlsx" className="mb-3 btn-link">Descargar plantilla</a>
                <div className='file-drop justify-center mt-5' {...getRootProps()}>
                  <input {...getInputProps({ multiple: false })} />
                  <p className="text-center mb-4">Arrastra y suelta tus archivo o presiona aquí para elegirlo.</p>
                </div>
                <p className='mt-3'><b>Archivo seleccionado:</b></p>
                <ul className="mb-5">
                  {
                    selectedFiles.map((file, key) => (
                      <li key={key}>
                        {file.name}
                      </li>
                    ))
                  }
                </ul>
                <Button className="btn btn-primary basic-button" disabled={selectedFiles.length <= 0} onClick={upload}>Subir archivo</Button>
              </Card.Body>
            </Card>
          </Col>
          {showRegisterStatus &&
            <Col xs={24} md={{ span: 8, offset: 2 }}>
              <Card className="mt-2 p-3">
                <Card.Body>
                  <h3>Se encontraron {asigneedData.length} registros fallidos</h3>
                  <Table striped bordered hover responsive className='table-styled mt-2'>
                    <thead>
                      <tr>
                        {columns.map((column, index) => (
                          <th key={index}>{column.text}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {asigneedData.slice(0, 20).map((record, rowIndex) => (
                        <tr key={rowIndex}>
                          {columns.map((column, colIndex) => (
                            <td key={colIndex}
                              style={{ backgroundColor: record.status === 'Fallido' ? '#E64444' : 'transparent' }}
                            >
                              {record[column.dataField]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          }
        </Row>
      </div>
    )
  }

  export default StudentsUpload;