import '../styles/ribbon.scss';

function Ribbon(){    
    return (
        <div className="ribbon">
            <div className="pink"></div>
            <div className="orange"></div>
            <div className="yellow"></div>
            <div className="cyan"></div>
            <div className="green"></div>
            <div className="pink"></div>
            <div className="orange"></div>
            <div className="yellow"></div>
            <div className="cyan"></div>
        </div>
            )
        }
export default Ribbon;