import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IFAQ } from '../../types';
import { request } from "../../services/Request";
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import FaqForm from "./FaqForm";
import { FaqTable } from "../../components/FaqTable";

interface FaqFom {
    openModal: boolean;
    onClose: () => void;
    defaultFaq?: IFAQ;
    isEditing?: boolean;
}

const FaqListAdmin = () => {
    const [faqs, setFaqs] = useState<IFAQ[] | null>(null)
    const [openModal, setOpenModal] = useState(false);

    const handleCancel = async () => {
        setOpenModal(false);
    };

    const [props, setProps] = useState<FaqFom>({
        openModal,
        onClose: handleCancel
    })

    const handleGetQuestions = async () => {
        request('/api/faq/getquestion', {}).then((res: any) => {
            if (res.status != 200) {
                setFaqs(null)
            } else {
                setFaqs(res.data);
            }
        }).catch((error: unknown) => {
            Swal.fire('error', (error as Error).message, 'error');
            setFaqs(null)
        });
    }

    useEffect(() => {
        if (!faqs) {
            handleGetQuestions();
        }
    });

    const handleDelete = (currentTopic: string) => {
        Swal.fire({
            title: `¿Estás seguro de eliminar el tema?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#34E825',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Eliminando tema, por favor espera',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                request('/api/faq/deletequestion/' + currentTopic, {
                    method: 'DELETE'
                }).then(async () => {
                    Swal.close();
                    await handleGetQuestions();
                    Swal.fire({
                        icon: 'success',
                        title: 'Se elimino el tema con éxito',
                    });
                }).catch((error: unknown) => {
                    Swal.close();
                    Swal.fire('error', (error as Error).message, 'error');
                });
            }
        })
    }

    const showFaqForm = (isEditing = false, faq?: IFAQ) => {
        const newProps = props;
        props.defaultFaq = isEditing && faq ? faq : undefined;
        props.isEditing = isEditing;
        props.openModal = true;
        setProps(newProps);
        setOpenModal(true);
    }


    return (
        <div className="content">
            <Row>
                <Col xs={24} md={{ span: 8, offset: 2 }}>
                    <Card className="mt-5">
                        <Card.Body>
                            <h1>Listado de temas</h1>
                            <Row style={{ justifyContent: 'right' }}>
                                <Col xs={12} md={{ span: 3 }}>
                                    <Button onClick={() => showFaqForm()} className="btn btn-primary mb-5 basic-button">Agregar tema</Button>
                                </Col>
                                <Accordion>
                                    {
                                        faqs?.map((faq: IFAQ, k) => (
                                            <Accordion.Item eventKey={k.toString()}>
                                                <Accordion.Header>{faq.topic}</Accordion.Header>
                                                <Accordion.Body>
                                                    <>
                                                        <Row className="m-3">
                                                            <Col>
                                                                <Button onClick={() => showFaqForm(true, faq)}>Editar Tema</Button>
                                                            </Col>
                                                            <Col>
                                                                <Button variant="danger" onClick={() => handleDelete(faq._id as string)}>Eliminar Tema</Button>
                                                            </Col>
                                                        </Row>
                                                        <FaqTable faq={faq} />
                                                    </>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))
                                    }
                                </Accordion>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {
                openModal && (
                    <FaqForm {...props}></FaqForm>
                )
            }
        </div>
    )
}

export default FaqListAdmin;