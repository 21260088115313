import React, { useState, useEffect, useContext } from 'react';
import { request } from '../services/Request';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { ResponseResult } from '../types';
import '../styles/header.scss';
import Ribbon from './Ribbon';
import Logo from '../assets/logos/logo-SEG-06.png';
import Session from '../context/Session';

function Header() {

	const [isAuth, setAuth] = useState(false);
	const [role, setRole] = useState(0);
	const [isLogged, setLogged] = useState(false);
	const session = useContext(Session);

	useEffect(() => {
		if (session._id != '' || session._id != undefined || session._id != null) {
			setLogged(true);
		}
	}, [session._id]);

	useEffect(() => {
		if (isLogged) {
			request('/api/auth/me', {
				noRedirect: true
			}).then((result: ResponseResult) => {
				if (result.data) {
					setAuth(true);
					setRole(result.data.role);
				}
			});
		}
	}, [session._id]);

	const close = () => {
		if (localStorage.token) {
			delete localStorage.token;
		}
		window.location.href = "/";
	}

	return (
		<React.Fragment>
			<Navbar variant="dark" expand="lg" className="header" fixed={"top"}>
				<Container>
					<Navbar.Brand href="/"><img src={Logo} alt="SEG" className='img-logo' /></Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							<Nav.Link href="/">Inicio</Nav.Link>
							{
								isAuth ? (
									<>
										<Nav.Link href="/questionnaires">Cuestionarios</Nav.Link>
										{
											role == 1 && (
												<>
													<Nav.Link href="/users">Usuarios</Nav.Link>
													<Nav.Link href="/schools">Planteles</Nav.Link>
													<Nav.Link href="/students/list">Alumnos</Nav.Link>
													<Nav.Link href="/reports">Reportes</Nav.Link>
													<Nav.Link href="/numerals">Numeralía</Nav.Link>
													<Nav.Link href="/answers">Respuestas</Nav.Link>
													<Nav.Link href="/answers">Avance</Nav.Link>
													<Nav.Link href="/faqs">P. Frecuentes</Nav.Link>
												</>

											)
										}
										{/* {
											role == 2 && (
												<Nav.Link href="/students">Alumnos</Nav.Link>
											)
										} */}
										<Nav.Link href="#" onClick={close}>Salir</Nav.Link>
									</>
								) : (
									<>
										<Nav.Link href="/login">Iniciar Sesión</Nav.Link>
										{
											!isAuth && (
												<Nav.Link href="/public/faqs">P. Frecuentes</Nav.Link>
											)
										}
									</>

								)
							}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Ribbon />
		</React.Fragment>
	)
}

export default Header;