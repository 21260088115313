import { useEffect, useState } from 'react';
import { Table, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import { IStudent } from '../../types';


function AllStudentsList() {

  const handleDownload = async () => {
    Swal.fire("Enviando reporte", "En un momento recibirás el reporte de todos los alumnos en tu correo electronico. ¡Gracias por tu paciencia!", "success");
    try {
      const response = await request('/api/student/report', {
        method: 'GET',
      });

      if (response && response.status === 200) {
        Swal.fire("Enviar reporte", "El reporte fue generado y envíado exitosamente al correo electronico", "success");
      } else {
        Swal.fire("Error", "Hubo un problema al generar el reporte de estudiantes", "error");
      }
    } catch (e: unknown) {
      Swal.fire("Alumnos", "Ocurrió un error al enviar el reporte", "error");
    }
  };

  const [students, setStudents] = useState<IStudent[]>([]);


  useEffect(() => {
    getStudents();
  }, []);

  const getStudents = async (text?: string) => {
    try {
      if (!text) {
        Swal.fire("Alumnos", "Cargando...");
        Swal.showLoading();
      }
      const result = await request(`/api/student/list${text ? '?text=' + text : ''}`, {});
      Swal.close();
      if (result.data) {
        setStudents(result.data);
      }
    } catch (e: unknown) {
      Swal.fire("Alumnos", "Ocurrió un error al cargar los datos", "error");
    }
  }

  const remove = (item: IStudent) => {
    Swal.fire({
      title: 'Eliminar alumno',
      html: `¿Confirmas que deseas eliminar al alumno <b>${item.name}</b>?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    })
      .then(async (result: any) => {
        if (result.isConfirmed) {
          Swal.fire("Eliminar alumno", "Realizando petición..");
          Swal.showLoading()
          const result = await request('/api/student/' + item._id, {
            method: 'DELETE'
          });
          if (result.data) {
            Swal.fire("Eliminar alumno", "El alumno se ha eliminado con exito", "success");
            getStudents();
          } else {
            Swal.fire("Eliminar plantrel", "Ocurrió un error al realizar el proceso", "error");
          }
        }
      })
  }

  const search = (e: any) => {
    const value = e.target.value;
    if (value != '') {
      getStudents(value);
    } else {
      getStudents();
    }
  }


  return (
    <div className="content list">
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <h2>Listado de alumnos</h2>
          <Card className='rounded-card'>
            <Card.Body>
              <Row style={{ justifyContent: 'right' }}>
                
                <Col xs={12} md={{ span: 3 }}>
                  <Link to="/students/new">
                    <Button variant="primary" className="w-100 mb-3 basic-button">Crear nuevo</Button>
                  </Link>
                </Col>
                <Col xs={12} md={{ span: 3 }}>
                  <Button variant="primary" className="w-100 mb-3 basic-button" onClick={handleDownload}>Descargar Alumnos</Button>
                </Col>
                <Col xs={12} md={{ span: 3 }}>
                  <Link to="/students/upload/register">
                    <Button variant="primary" className="w-100 mb-3 basic-button">Subir Alumnos</Button>
                  </Link>
                </Col>
                <Col xs={12} md={{ span: 3 }}>
                  <Link to="/students/upload/delete">
                    <Button variant="primary" className="w-100 mb-3 basic-button">Borrar Alumnos</Button>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="searchForm" className='mb-3'>
                    <Form.Control
                      type="text"
                      placeholder="Buscar por CURP, Nombre, Turno, CCT, Plantel o Subsistema."
                      onChange={search}
                      className='field-styled'
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Table striped bordered hover responsive className='table-styled'>
                <thead>
                  <tr>
                    <th>CURP</th>
                    <th>Nombre completo</th>
                    <th>Grado</th>
                    <th>Turno</th>
                    <th>CCT</th>
                    <th>Plantel</th>
                    <th>Subsistema</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    students.map((item: IStudent, key: number) => (
                      <tr key={"student_" + key}>
                        <td>{item.curp}</td>
                        <td>{item.name}</td>
                        <td>{item.year}°</td>
                        <td>{item.schedule}</td>
                        <td>{item.cct}</td>
                        <td>{item.school}</td>
                        <td>{item.subsystem}</td>
                        <td style={{ textAlign: 'center' }}>
                          <Link to={"/students/edit/" + item._id}>
                            <Button variant="primary" className='action-button' title="Editar">Editar</Button>
                          </Link>
                          <Button variant="danger" className="mt-2 mt-xl-0 action-button" onClick={() => { remove(item) }} title="Eliminar">Eliminar</Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AllStudentsList;
