import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { GoogleOAuthProvider } from '@react-oauth/google';

Sentry.init({
  dsn: "https://7043c0554f7045529cade1235e788eec@o1141627.ingest.sentry.io/4505132931153920",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event) {
    // Verificar si el error es un error 403
    if (event.exception && event.exception.values && event.exception.values[0] && event.exception.values[0].type === 'HttpError' && event.exception.values[0].value === '403') {
      // Ignorar el error 403
      return null;
    }
    // Enviar todos los demás errores
    return event;
  }
});

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <GoogleOAuthProvider
        clientId="25798192880-pstek8m0kh4ttn06vub1qj1s9m4flflg.apps.googleusercontent.com"
      >
        <App />
      </GoogleOAuthProvider>
    </MsalProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
