import '../styles/questionnaires.scss';
import { Questionnaire } from '../types';
import { Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { request } from '../services/Request';
import { FileEarmarkBarGraph, PencilSquare, XSquare, JournalCheck, Search } from 'react-bootstrap-icons'

function QuestionnaireCard({ questionnaire, callback }: { questionnaire: Questionnaire, callback: any }) {

	const iconSize = 30;

	const remove = (item: Questionnaire) => {
		Swal.fire({
			title: 'Eliminar cuestionario',
			html: `¿Confirmas que deseas eliminar el cuestionario <b>${item.name}</b>?`,
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Sí',
			cancelButtonText: 'No'
		})
			.then(async (result: any) => {
				if (result.isConfirmed) {
					Swal.fire("Eliminar cuestionario", "Realizando petición..");
					Swal.showLoading()
					const result = await request('/api/questionnaire/' + item._id, {
						method: 'DELETE'
					});
					if (result.data) {
						Swal.fire("Eliminar cuestionario", "El cuestionario se ha eliminado con exito", "success");
						callback();
					} else {
						Swal.fire("Eliminar cuestionario", "Ocurrió un error al realizar el proceso", "error");
					}
				}
			})
	}

	const sendResults = (item: Questionnaire) => {
		Swal.fire({
			title: 'Enviar resultados',
			html: `¿Confirmas que deseas enviarte los resultados del cuestionario <b>${item.name}</b> a tu correo?`,
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Sí',
			cancelButtonText: 'No'
		})
			.then(async (result: any) => {
				if (result.isConfirmed) {
					Swal.fire("Enviar resultados", "Realizando petición..");
					Swal.showLoading()
					const result = await request('/api/questionnaire/results/' + item._id + "/complete/0/0", {
						method: 'GET'
					});
					if (result.data) {
						Swal.fire("Enviar resultados", "La petición se realizó con éxito. En unos minutos estará llegando a su cuenta de correos.", "success");
					} else {
						Swal.fire("Enviar resultados", "Ocurrió un error al realizar el proceso", "error");
					}
				}
			})
	}

	return (
		<div className="questionnaire-card">
			<div >
				<Row className="align-items-center">
					<Col xs={10}>
						<a href={'/questionnaires/edit/' + questionnaire._id} title="Editar">
							<JournalCheck size={200} className='w-100 icn-questionnaire' />
						</a>
					</Col>
					<Col xs={2} className="p-0">
						<div className="cursor-pointer">
							<div className='questionnaire-card-option'>
								<a href={'/questionnaires/edit/' + questionnaire._id} title="Editar" className='edit'><PencilSquare size={iconSize}/></a>
							</div>
							<div className='questionnaire-card-option'>
								<a onClick={() => remove(questionnaire)} title="Eliminar" className='delete'><XSquare size={iconSize} /></a>
							</div>
							<div className='questionnaire-card-option'>
								<a href={'/responder/' + questionnaire.slug} title="Vista previa" className='preview'><Search size={iconSize}/></a>
							</div>
							<div className='questionnaire-card-option'>
								<a onClick={() => sendResults(questionnaire)} title="Descargar reporte completo" className='report'><FileEarmarkBarGraph size={iconSize} /></a>
							</div>
						</div>
					</Col>
					<Col>
						<p className="ms-3 text-center text-md-left">{questionnaire.name}</p>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default QuestionnaireCard;