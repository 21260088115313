import { useState, useEffect } from 'react';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { User } from '../../types';
import { Table, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function UserList() {

	const [users, setUsers] = useState<User[]>([]);

	useEffect(() => {
		getUsers();
	}, []);

	const getUsers = async () => {
		try {
			Swal.fire("Usuarios", "Cargando...");
			Swal.showLoading();
			const result = await request('/api/user', {});
			Swal.close();
			if (result.data) {
				setUsers(result.data);
			} else {
				Swal.fire("Usuarios", "Ocurrió un error al cargar los datos", "error");
			}
		} catch (e: unknown) {
			Swal.fire("Usuarios", "Ocurrió un error al cargar los datos", "error");
		}
	}

	const remove = (item: User) => {
		Swal.fire({
			title: 'Eliminar usuario',
			html: `¿Confirmas que deseas eliminar el usuario <b>${item.name}</b>?`,
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Sí',
			cancelButtonText: 'No'
		})
			.then(async (result: any) => {
				if (result.isConfirmed) {
					Swal.fire("Eliminar usuario", "Realizando petición..");
					Swal.showLoading()
					const result = await request('/api/user/' + item._id, {
						method: 'DELETE'
					});
					if (result.data) {
						Swal.fire("Eliminar usuario", "El usuario se ha eliminado con exito", "success");
						getUsers();
					} else {
						Swal.fire("Eliminar usuario", "Ocurrió un error al realizar el proceso", "error");
					}
				}
			})
	}

	/* const toggle = (item: User) => {
		Swal.fire({
			title: item.status ? 'Desactivar usuario' : 'Activar usuario',
			html: `¿Confirmas que deseas cambiar el estatus del usuario <b>${item.name}</b>?`,
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Sí',
			cancelButtonText: 'No'
		})
			.then(async (result: any) => {
				if (result.isConfirmed) {
					Swal.fire("Eliminar usuario", "Realizando petición..");
					Swal.showLoading()
					const result = await request('/api/user/toggle/' + item._id, {
						method: 'PUT',
						body: JSON.stringify({
							status: !item.status
						})
					});
					if (result.data) {
						Swal.fire(item.status ? 'Desactivar usuario' : 'Activar usuario', "Se ha cambiado el estatus del usuario.", "success");
						getUsers();
					} else {
						Swal.fire(item.status ? 'Desactivar usuario' : 'Activar usuario', "Ocurrió un error al realizar el proceso.", "error");
					}
				}
			})
	} */    

	return (
        <div className="content list">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <h2>Usuarios</h2>
                    <Card>
                        <Card.Body>
                            <Row>
                            <Col xs={12} md={{span:4,offset:4}}>
                                    <Link to="/users/upload">                                    
                                        <Button variant="primary" className="w-100 mb-3">Subir</Button>
                                    </Link>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Link to="/users/new">                                    
                                        <Button variant="primary" className="w-100 mb-3">Crear nuevo</Button>
                                    </Link>
                                </Col>
                            </Row>
                            <Table striped bordered hover responsive className='table-styled'>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Email</th>
                                        <th>Estatus</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users.map((item:User, key: number)=>(
                                            <tr key={key}>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.status ? 'Activo' : 'Inactivo'}</td>
                                                <td>
                                                    <Link to={"/users/edit/"+item._id}>                                    
                                                        <Button variant="primary" className='action-button'>Editar</Button>
                                                    </Link>
                                                    <Button variant="danger" className="mt-2 action-button" onClick={()=>{remove(item)}}>Eliminar</Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default UserList;