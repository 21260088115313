import { Row, Col, Card, Badge } from 'react-bootstrap';
import '../styles/login.scss';
import { useEffect, useState } from 'react';
import { request } from '../services/Request';
import Swal from 'sweetalert2';


function AppConfig() {

  const [diagnosticEnabled, setDiagnosticEnabled] = useState(false);

  useEffect(() => {
    getDiagnosticStatus();
  }, [])

  const getDiagnosticStatus = async () => {
    try {
      const result = await request("/api/config/getDiagnosticStatus", {});
      if (result) {
        setDiagnosticEnabled(result.data.diagnostic_status);
      }
    } catch (e) {
      //console.log('Error', e);
      throw new Error(e as string)
    }
  }

  const changeDiagnosticStatus = () => {
    const status = diagnosticEnabled === true ? false : true;
    Swal.fire({
      title: `${diagnosticEnabled === true ? 'Deshabilitar' : 'Habilitar'} pruebas`,
      html: `¿Confirmas que deseas ${diagnosticEnabled === true ? 'deshabilitar' : 'habilitar'} las <b>pruebas de diagnóstico</b>?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    })
      .then(async (result: any) => {
        if (result.isConfirmed) {
          Swal.fire("Deshabilitar pruebas", "Realizando petición...");
          Swal.showLoading()
          const result = await request('/api/config/changeDiagnosticStatus', {
            method: 'POST',
            body: JSON.stringify({ status })
          });
          if (result.data) {
            Swal.fire(`${diagnosticEnabled === true ? 'Deshabilitar' : 'Habilitar'} pruebas`, `Las pruebas se ${diagnosticEnabled === true ? 'deshabilitaron' : 'habilitaron'} correctamente.`, "success");
            getDiagnosticStatus();
          } else {
            Swal.fire(`${diagnosticEnabled === true ? 'Deshabilitar' : 'Habilitar'} pruebas`, `Ocurrió un error al ${diagnosticEnabled === true ? 'deshabilitaron' : 'habilitaron'} las pruebas.`, "error");
          }
        }
      })
  }


  return (
    <div className="content">
      <Row className='mt-5'>
        <Col xs={12} md={12} lg={12}>
          <h2>Configuración</h2>
        </Col>
      </Row>
      <Row className='justify-content-center mt-3'>
        <Col xs={10}>
          <Card className='rounded-card'>
            <Card.Body>
              <Row>
                <Col xs={3}>
                  <h3>
                    Diagnóstico:
                  </h3>
                </Col>
                <Col xs={3}>
                  <h3>
                    {
                      diagnosticEnabled && diagnosticEnabled === true ? (
                        <Badge pill bg="success" onClick={changeDiagnosticStatus}>
                          Habilitado
                        </Badge>
                      ) : (
                        <Badge pill bg="warning" text="dark" onClick={changeDiagnosticStatus}>
                          Deshabilitado
                        </Badge>
                      )
                    }
                  </h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AppConfig;
