import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { request } from "../services/Request";
import TimePicker from "react-time-picker";
import { useState } from "react";

function Numerals() {

  const [startTime, setStartTime]: any = useState(null);
  const [endTime, setEndTime]: any = useState(null);
  const [tableData, setTableData]: any = useState({});

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    tableData['start_time'] = startTime;
    tableData['end_time'] = endTime;
    if (!tableData['start_date'] ||
      !tableData['end_date'] ||
      !tableData['start_time'] ||
      !tableData['end_time']
    ) {
      Swal.fire("Error", "Por favor, ingresa todos los datos solicitados ", "error")
    } else {   
      generateNumeralsTable(tableData);
    }
  }

  const generateNumeralsTable = async (data: any) => {
    try {
      Swal.fire("Generando numeralía...", "Por favor, espera mientras se cargan los datos. Esto puede tomar algunos minutos. Espera sin cerrar esta ventana.");
      Swal.showLoading();
      const result = await request("/api/questionnaire/numerals", {
        method: "POST",
        body: JSON.stringify(data)
      });
      if(result) {
        Swal.fire("¡Exito!", "¡La Numeralía se generó y se envió exitosamente a tu correo electrónico!", "success");
      }
    } catch (e: unknown) {
      Swal.fire("Error", "Ocurrió un error al generar la numeralía", "error");
    }
  }

  const onChange = (e: any) => {
    const data = tableData;
    data[e.target.name] = e.target.value;
    setTableData(data);
  }


  return (
    <div id="reports" className="content">
      <Row className='mt-lg-5'>
        <Col xs={12} md={{span:10,offset:1}} lg={12}>
          <h2>Numeralía</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={{span:10,offset:0}}>
          <Card className='mt-5 p-3'>
            <Card.Body>
              <Form onSubmit={submit}>
                <Form.Group>
                  <Row className='justify-content-center'>
                    <Row>
                      <Col xs={12} className='mb-3'>
                        <Alert variant='info'>
                          <p style={{ marginBottom: '0px', textAlign: 'center' }}><b>Importante: Por favor, asegúrate de establecer las horas en formato de 24 horas.</b></p>
                        </Alert>
                      </Col>
                    </Row>
                    <Col md={5}>
                      <Row>
                        <Col xs={12} className='mb-2'>
                          <Form.Label>Fecha de inicio</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <Form.Group controlId={"start_date"}>
                            <Form.Control type="date" name={'start_date'} onChange={onChange} className='field-styled' />
                            <Form.Control.Feedback type="invalid">Es necesario llenar este campo</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3} className="mt-4 mt-md-0">
                      <Row>
                        <Col xs={12} className='mb-2'>
                          <Form.Label>Hora de inicio</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <TimePicker
                            onChange={setStartTime}
                            value={startTime}
                            clearIcon={null}
                            disableClock
                            format="HH:mm"
                            hourPlaceholder="HH"
                            minutePlaceholder="mm"
                            className='time-picker'
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='justify-content-center mt-4'>
                    <Col md={5}>
                      <Row>
                        <Col xs={12} className='mb-2'>
                          <Form.Label>Fecha de fin</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <Form.Group controlId={"end_date"}>
                            <Form.Control type="date" name={'end_date'} onChange={onChange} className='field-styled' />
                            <Form.Control.Feedback type="invalid">Es necesario llenar este campo</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3} className="mt-4 mt-md-0">
                      <Row>
                        <Col xs={12} className='mb-2'>
                          <Form.Label>Hora de fin</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <TimePicker
                            onChange={setEndTime}
                            value={endTime}
                            clearIcon={null}
                            disableClock
                            format="HH:mm"
                            hourPlaceholder="HH"
                            minutePlaceholder="mm"
                            className='time-picker'
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                <Row className="mt-5 justify-center">                 
                  <Col xs={12} md={3}>
                    <Button variant="primary" className="w-100 basic-button basic-button" type="submit">
                      Generar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Numerals