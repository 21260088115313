import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { ResponseResult, RouteParam, ISchool } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function SchoolEdit() {

  const [schoolData, setSchoolData] = useState<Partial<ISchool>>({});
  const { id } = useParams<RouteParam>();

  useEffect(() => {
    getStudent();
  }, []);

  const getStudent = () => {
    request('/api/school/' + id, {})
      .then((result: ResponseResult) => {
        if (result.data) {
          setSchoolData(result.data);
        }
      })
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!schoolData['administrative_entity']
      || !schoolData['municipality']
      || !schoolData['cct']
      || !schoolData['name']
      || !schoolData['subsystem']) {
      Swal.fire("Editar plantel", "Es necesario completar todos los campos", "error");
    } else {
      Swal.fire("Editar plantel", "Guardando registro...");
      Swal.showLoading();
      request("/api/school/" + id, {
        method: 'PUT',
        body: JSON.stringify(schoolData)
      })
        .then((result: ResponseResult) => {
          Swal.close();
          if (result.data) {
            Swal.fire("Editar plantel", "Datos del plantel actualizados con éxito !", "success")
              .then(() => {
                window.location.href = "/schools";
              })
          } else {
            Swal.fire("Plantel", "Ocurrió un error al guardar el plantel", "error");
          }
        })
    }
  }

  const onChange = (e: any) => {
    const data = schoolData;
    data[e.target.name] = e.target.value.toUpperCase();
    setSchoolData(data);
  }

  return (
    <div id="users-form" className="content">
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <h2 className="mt-5 mb-5">Editar plantel</h2>
          <Card className='rounded-card p-3 p-md-5'>
            <Card.Body>
              <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="administrative_entity">
                  <Form.Label><b>Entidad Administrativa</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="administrative_entity" onChange={onChange} defaultValue={schoolData.administrative_entity} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="municipality">
                  <Form.Label><b>Municipio</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="municipality" onChange={onChange} defaultValue={schoolData.municipality} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="cct">
                  <Form.Label><b>CCT</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="cct" onChange={onChange} defaultValue={schoolData.cct} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label><b>Nombre</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="name" onChange={onChange} defaultValue={schoolData.name} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="subsystem">
                  <Form.Label><b>Subsistema</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="subsystem" onChange={onChange} defaultValue={schoolData.subsystem} className='field-styled' />
                </Form.Group>
                <Row className="mt-5 justify-right">
                  <Col xs={12} md={4}>
                    <a className="ml-3 btn btn-danger w-100" href="/schools">
                      Cancelar
                    </a>
                  </Col>
                  <Col xs={12} md={4}>
                    <Button variant="primary" type="submit" className="w-100  basic-button">
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default SchoolEdit;