import { ReactNode, useState } from "react";
import { Accordion, Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { IStudent, ResponseResult } from "../types";
import Swal from "sweetalert2";
import { request } from "../services/Request";

function Answers() {

  const [studentCurp, setStudentCurp] = useState('');
  const [studentAnswers, setstudentAnswers] = useState<Partial<IStudent>>();
  const [showModal, setShowModal] = useState(false);

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!studentCurp || !validateCurp(studentCurp)) {
      Swal.fire("Error", "La CURP ingresada es no es válida", "error");
    } else {
      Swal.fire("Buscar respuesta", "Buscando respuesta...");
      Swal.showLoading();
      request("/api/answer", {
        method: 'POST',
        body: JSON.stringify({ curp: studentCurp })
      })
        .then((result: ResponseResult) => {
          Swal.close();
          if (result.data) {
            setstudentAnswers(result.data.studentAnswers);
            setShowModal(true);
          } else {
            Swal.fire("Sin respuestas", "El alumno aun no ha respondido el cuestionario", "error");
          }
        })
    }
  }

  const onChange = (e: any) => {
    let data = studentCurp;
    data = e.target.value;
    setStudentCurp(data);
  }

  const validateCurp = (text: string) => {
    const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    const flag = text.match(regex);
    return flag;
  }

  return (
    <div id="reports" className="content">
      <Row className='mt-lg-5'>
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <h2>Buscar respuestas</h2>
          <p>En esta sección, podrás verificar si se registró la respuesta de un alumno por medio de su CURP, sólo tienes que ingresar los datos solicitados.</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <Card className='mt-5 p-3'>
            <Card.Body>
              <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="cct">
                  <Form.Label><b>CURP del estudiante</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="cct" onChange={onChange} className='field-styled' />
                </Form.Group>
                <Row className="mt-5 justify-center">
                  <Col xs={12} md={3}>
                    <a className="ml-3 btn btn-danger w-100" href="/students/list" title='Cancelar'>
                      Cancelar
                    </a>
                  </Col>
                  <Col xs={12} md={3}>
                    <Button variant="primary" type="submit" className="w-100 mt-2 mt-md-0">
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => { setShowModal(false) }} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <b>
              Resultados de la búsqueda
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            studentAnswers && (
              <>
                <div className="mb-2">
                  <b>Información del alumno: </b>
                </div>
                <Table striped bordered hover responsive className='table-styled'>
                  <thead>
                    <tr>
                      <th>CURP</th>
                      <th>Nombre</th>
                      <th>Turno</th>
                      <th>CCT</th>
                      <th>Subsistema</th>
                      <th>Plantel</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      <tr>
                        <td>
                          {studentAnswers.curp}
                        </td>
                        <td>
                          {studentAnswers.name}
                        </td>
                        <td>
                          {studentAnswers.schedule}
                        </td>
                        <td>
                          {studentAnswers.cct}
                        </td>
                        <td>
                          {studentAnswers.subsystem}
                        </td>
                        <td>
                          {studentAnswers.school}
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
                <p>
                  {studentAnswers.answers.length === 1 ? 'Se encontró 1 respuesta' : `Se encontraron ${studentAnswers.answers.length} respuestas`}
                </p>
                {
                  studentAnswers.answers.map((answer: any, index: number) => (
                    <Accordion defaultActiveKey="1" key={'answer' + index + 1}>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Respuesta #{index + 1}</Accordion.Header>
                        <Accordion.Body>
                          <Table striped bordered hover responsive className='table-styled'>
                            <thead>
                              <tr>
                                {/*<th>{answer.questionnaire.name}</th>*/}
                                <th>{'Pregunta'}</th>
                                <th>{'Respuesta'}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(answer.fields).map(([key, res], i) => (
                                <tr key={i}>
                                  <td>{key}</td>
                                  <td>{res as ReactNode}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))
                }
              </>
            )
          }
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default Answers; 