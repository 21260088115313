import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import SessionContext from '../context/Session';

const ProtectedRoute = ({
    redirectPath = '/login',
    children,
  }:any) => {

    const user = useContext(SessionContext);
    if (!user.role) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return children;
};

  export default ProtectedRoute;