import React, { useState } from 'react';
import { Card, Row, Col, Button, Table } from 'react-bootstrap';
import { ResponseResult } from '../../types';
import { request } from '../../services/Request';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';

function SchoolUpload() {

  interface NewBadRegister {
    name: string;
    status: string;
    description: string;
    [key: string]: string;
  }
  const columns = [
    {
      dataField: 'name',
      text: 'Plantel',
    },
    {
      dataField: 'status',
      text: 'Estatus',
    },
    {
      dataField: 'description',
      text: 'Descripción',
    },
  ];

  const [asigneedData, setAsigneedData] = useState<NewBadRegister[]>([]);
  const [selectedFiles, setFiles]: any = useState([]);
  const [showRegisterStatus, setShowRegisterStatus] = useState(false);

  const onDrop = (acceptedFiles: any) => {
    let files = selectedFiles;
    files = acceptedFiles;
    setFiles(files);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xlsx']
    }
  });

  const upload = () => {
    if (selectedFiles.length > 0) {
      Swal.fire('Subir planteles', 'Cargando...');
      Swal.showLoading();
      const data = new FormData();
      for (const file of selectedFiles) {
        data.append('files', file);
      }
      request('/api/school/upload', {
        method: 'POST',
        file: true,
        body: data
      })
        .then((result: ResponseResult) => {
          Swal.close();
          if (result.data) {

            if (result.data.newBadRegisters) {
              setAsigneedData(result.data.newBadRegisters);

              Swal.fire('Error al subir planteles', 'Existen problemas con algunos registros del archivo', 'error')
                .then(() => {
                  setShowRegisterStatus(true);
                })
            }else{
              Swal.fire('Subir planteles', 'Los planteles se subieron correctamente', 'success')
              .then(() => {
                window.location.href = "/schools"
              })
            }
            
          } else {
            Swal.fire('Subir planteles', 'Ocurrió un error', 'error');
          }
        })
    }
  }

  return (
    <div id="upload" className="content">
      <Row>
        <Col xs={24} md={{ span: 8, offset: 2 }}>
          <Card className="mt-md-5 p-3">
            <Card.Body>
              <h3>Subir planteles por excel</h3>
              <a href="/PlantillaPlanteles.xlsx" className="mb-3 btn-link">Descargar plantilla</a>
              <div className='file-drop justify-center mt-5' {...getRootProps()}>
                <input {...getInputProps({ multiple: false })} />
                <p className="text-center mb-4">Arrastra y suelta tus archivo o presiona aquí para elegirlo.</p>
              </div>
              <p className='mt-3'><b>Archivo seleccionado:</b></p>
              <ul className="mb-5">
                {
                  selectedFiles.map((file: any, key: number) => (
                    <li key={key}>
                      {file.name}
                    </li>
                  ))
                }
              </ul>
              <Button className="btn btn-primary basic-button" disabled={selectedFiles.length <= 0 } onClick={upload}>Subir archivo</Button>
            </Card.Body>
          </Card>
        </Col>
        {showRegisterStatus &&
          <Col xs={24} md={{ span: 8, offset: 2 }}>
            <Card className="mt-2 p-3">
              <Card.Body>
                <h3>Se encontraron {asigneedData.length} registros fallidos</h3>
                <Table striped bordered hover responsive className='table-styled mt-2'>
                  <thead>
                    <tr>
                      {columns.map((column, index) => (
                        <th key={index}>{column.text}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {asigneedData.slice(0,20).map((record, rowIndex) => (
                      <tr key={rowIndex}>
                        {columns.map((column, colIndex) => (
                          <td key={colIndex}
                            style={{ backgroundColor: record.status === 'Fallido' ? '#E64444' : 'transparent' }}
                          >
                            {record[column.dataField]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        }
      </Row>
    </div>
  )
}

export default SchoolUpload;