import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { request } from '../../services/Request';
import { Input, Select } from '../../components/FormComponents';
import '../../styles/form.scss';
import Rectangle from '../../assets/rectangle.png';
import Swal from 'sweetalert2';
import { Student, School } from '../../types';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Logo1 from '../../assets/gto_logo.png';
import Logo2 from '../../assets/rima_logo.png';
import Logo3 from '../../assets/pacto_logo.png';
import Logo4 from '../../assets/logo_1.png';

function TeacherRegister(){

    const [userData, setUserData]:any = useState<Partial<Student>>({});
    //const [student, setStudent] = useState<Partial<Student>>({});
    //const [showStudent, setShowStudent] = useState(false);
    //const [rejected, setRejected] = useState(0);
    const [_, setSchools] = useState<School[]>([]);
    const [subsystems, setSubsystems] = useState<School[]>([]);

    useEffect(() => {
        getSchools();
        getSubsystems();
    }, []);

    const getSchools = async() => {
        try{
            Swal.fire("Docentes", "Cargando escuelas...");
            Swal.showLoading();
            const result = await request("/api/student/schools/curp",{
            });
            Swal.close();
            if(result.data){
                setSchools(result.data);
            }else{
                Swal.fire("Docentes","Ocurrió un error al cargar las escuelas. Error 2.","error");
            }
        }catch(e:unknown){
            Swal.fire("Docentes","Ocurrió un error al cargar las escuelas. Error 1.","error")
        }
    } 

    const getSubsystems = async() => {
        try{
            Swal.fire("Docentes", "Cargando subsistemas...");
            Swal.showLoading();
            const result = await request("/api/student/subsystems",{
            });
            Swal.close();
            if(result.data){
                setSubsystems(result.data);
            }else{
                Swal.fire("Docentes","Ocurrió un error al cargar los subsistemas. Error 2.","error");
            }
        }catch(e:unknown){
            Swal.fire("Docentes","Ocurrió un error al cargar los subsistemas. Error 1.","error")
        }
    } 

    const createStudent = async() => {
        try{
            if(!userData.name ||
                !userData.gender ||
                !userData.phone ||
                !userData.position ||
                !userData.subsystem ||
                !userData.email ||
                !userData.email2 ||
                !userData.password ||
                !userData.password2){
                Swal.fire("Docentes","Hay que completar todos los campos","error");
            }else if( userData.password != userData.password2){
                Swal.fire("Docentes","Las contraseñas no coinciden","error");
            }else if( userData.email != userData.email2){
                Swal.fire("Docentes","Los correos no coinciden","error");
            }else{
                Swal.fire("Docentes", "Creando registro...");
                Swal.showLoading();
                const result = await request("/api/user/teacher",{
                    method: 'POST',
                    body: JSON.stringify(userData)
                });
                Swal.close();
                if(result.data){
                    Swal.fire("Docentes", "Se ha enviado un correo electrónico a su cuenta para continuar con el proceso", "success");
                }else{
                    if(result.error.status == 420){
                        Swal.fire("Docentes","Ya existe un usuario con este correo","error");
                    }else{
                        Swal.fire("Docentes","Ocurrió un error al registrarte como docente. Error 2.","error");
                    }
                }
            }
        }catch(e:unknown){
            Swal.fire("Docentes","Ocurrió un error al registrarte como docente. Error 1.","error")
        }
    } 

    const onChange = (e:any) => {
        const data = {...userData};
        data[e.target.name] = e.target.value.trim();
        setUserData(data);
    }

    /*const onChangeSchool = (e:any) => {
        const data = {...userData};
        data['cct'] = e[0] ? e[0].cct : undefined;
        data['school'] = e[0] ? e[0].school : undefined;
        setUserData(data);
    } */

    const onChangeSubsystem = (e:any) => {
        const data = {...userData};
        data['subsystem'] = e[0] ? e[0].subsystem : undefined;
        setUserData(data);
    }

    return (
        <div className='answer-form content preview'> 
            <div className="logos">
                <Row>
                    <Col xs={12} md={5} className="p-0 mt-3 mb-3 p-relative">
                        <img src={Rectangle} className="rectangle"></img>
                        <div className="overlay">
                            <Row className="align-items-center">
                                <Col xs={3} md={3} className="pl-0">
                                    <img src={Logo1} className="logo-1"></img>
                                </Col>
                                <Col xs={3} md={3}>
                                    <img src={Logo2} className="logo-2"></img>
                                </Col>
                                <Col xs={2} md={2}>
                                    <img src={Logo3} className="logo-2"></img>
                                </Col>
                                <Col xs={3} md={3}>
                                    <img src={Logo4} className="logo-2"></img>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col xs={12} md={{offset:2, span:8}}>
                    <h2 className="mt-5 mb-5">Registro como enlace RIMA</h2>
                        <Card>
                            <Card.Body>
                                <div className="mt-3">
                                    <Input
                                    onChange={onChange}
                                    item={{
                                        title:"Nombre completo:",
                                        type:'text',
                                        name: 'name',  
                                    }}></Input>
                                </div>
                                <div className="mt-3">
                                    <Select
                                    onChange={onChange}
                                    item={{
                                        title:"Sexo",
                                        type:'text',
                                        name: 'gender',  
                                        options: [
                                            {
                                                label: 'Masculino',
                                                value: 'Masculino'
                                            },
                                            {
                                                label: 'Femenino',
                                                value: 'Femenino'
                                            }
                                        ]
                                    }}></Select>
                                </div>
                                <div className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Seleccione su subsistema:</Form.Label>
                                        <Typeahead
                                        id="schools"
                                        labelKey={ (option:any) => `${option.subsystem}`}
                                        onChange={onChangeSubsystem}
                                        options={subsystems}
                                        placeholder="Escribe el nombre..."
                                        />
                                    </Form.Group>
                                </div>
                                <div className="mt-3">
                                    <Input
                                    onChange={onChange}
                                    item={{
                                        title:"Teléfono de contacto:",
                                        type:'text',
                                        name: 'phone',  
                                    }}></Input>
                                </div>
                                <div className="mt-3">
                                    <Select
                                    onChange={onChange}
                                    item={{
                                        title:"Puesto que actualmente desempeñan",
                                        type:'text',
                                        name: 'position',  
                                        options: [
                                            {
                                                label: 'Director',
                                                value: 'Director'
                                            },
                                            {
                                                label: 'Subdirector',
                                                value: 'Subdirector'
                                            },
                                            {
                                                label: 'Jefe académico',
                                                value: 'Jefe académico'
                                            },
                                            {
                                                label: 'Profesor',
                                                value: 'Profesor'
                                            },
                                            {
                                                label: 'Administrativo/Personal de apoyo',
                                                value: 'Administrativo/Personal de apoyo'
                                            }
                                        ]
                                    }}></Select>
                                </div>
                                <div className="mt-3">
                                    <Input
                                    onChange={onChange}
                                    item={{
                                        title:"Correo electrónico",
                                        type:'email',
                                        name: 'email',  
                                    }}></Input>
                                </div>
                                <div className="mt-3">
                                    <Input
                                    onChange={onChange}
                                    item={{
                                        title:"Repetir correo electrónico:",
                                        type:'email',
                                        name: 'email2',  
                                    }}></Input>
                                </div>
                                <div className="mt-3">
                                    <Input
                                    onChange={onChange}
                                    item={{
                                        title:"Contraseña:",
                                        type:'text',
                                        name: 'password',  
                                    }}></Input>
                                </div>
                                <div className="mt-3">
                                    <Input
                                    onChange={onChange}
                                    item={{
                                        title:"Repetir contraseña:",
                                        type:'text',
                                        name: 'password2',  
                                    }}></Input>
                                </div>
                                <Row className="mt-3 justify-content-center">
                                    <Col xs={12} md={4}>
                                        <Button className="w-100" onClick={() => createStudent()}>Guardar</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                </Col>
            </Row>
        </div>
    );
}

export default TeacherRegister;