import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IFAQ } from '../../types';
import { request } from "../../services/Request";
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { FaqTable } from "../../components/FaqTable";

const FaqListPublic = () => {
    const [faqs, setFaqs] = useState<IFAQ[] | null>(null)

    const handleGetQuestions = async () => {
        request('/api/faq/getquestion', {}).then((res: any) => {
            if (res.status != 200) {
                setFaqs(null)
            } else {
                setFaqs(res.data);
            }
        }).catch((error: unknown) => {
            Swal.fire('error', (error as Error).message, 'error');
            setFaqs(null)
        });
    }

    useEffect(() => {
        if (!faqs) {
            handleGetQuestions();
        }
    });


    return (
        <div className="content">
            <Row>
                <Col xs={24} md={{ span: 8, offset: 2 }}>
                    <Card className="mt-5">
                        <Card.Body>
                            <h1>Preguntas Frecuentes</h1>
                            <Row style={{ justifyContent: 'right' }}>
                                <Accordion>
                                    {
                                        faqs?.map((faq: IFAQ, k) => (
                                            <Accordion.Item eventKey={k.toString()}>
                                                <Accordion.Header>{faq.topic}</Accordion.Header>
                                                <Accordion.Body>
                                                    <FaqTable faq={faq} />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))
                                    }
                                </Accordion>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default FaqListPublic;