import React, { useState } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { User, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function SchoolNew() {

  const [schoolData, setSchoolData] = useState<Partial<User>>({});

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!schoolData['administrative_entity']
      || !schoolData['municipality']
      || !schoolData['cct']
      || !schoolData['name']
      || !schoolData['subsystem']) {
      Swal.fire("Nuevo plantel", "Es necesario completar todos los campos", "error");
    } else {
      Swal.fire("Nuevo plantel", "Guardando registro...");
      Swal.showLoading();
      request("/api/school", {
        method: 'POST',
        body: JSON.stringify(schoolData)
      })
        .then((result: ResponseResult) => {
          Swal.close();
          if (result.data) {
            window.location.href = "/schools";
          } else {
            Swal.fire("Plantel", "Ocurrió un error al guardar el plantel", "error");
          }
        })
    }
  }

  const onChange = (e: any) => {
    const data = schoolData;
    data[e.target.name] = e.target.value.toUpperCase();
    setSchoolData(data);
  }

  return (
    <div id="users-form" className="content">
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <h2 className="mt-lg-5 mb-5">Nuevo plantel</h2>
          <Card className='p-3'>
            <Card.Body>
              <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="administrative_entity">
                  <Form.Label><b>Entidad Administrativa</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="administrative_entity" onChange={onChange} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="municipality">
                  <Form.Label><b>Municipio</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="municipality" onChange={onChange} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="cct">
                  <Form.Label><b>CCT</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="cct" onChange={onChange} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label><b>Nombre</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="name" onChange={onChange} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="subsystem">
                  <Form.Label><b>Subsistema</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="subsystem" onChange={onChange} className='field-styled' />
                </Form.Group>
                <Row className="mt-5 justify-right">
                  <Col xs={12} md={4}>
                    <a className="ml-3 btn btn-danger w-100" href="/schools">
                      Cancelar
                    </a>
                  </Col>
                  <Col xs={12} md={4}>
                    <Button variant="primary" type="submit" className="w-100 mt-2 mt-md-0 basic-button">
                      Crear
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default SchoolNew;