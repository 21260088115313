import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { IFAQ } from '../../types';
import { request } from "../../services/Request";
import { useEffect, useState } from "react";


interface CreateModal {
    openModal: boolean;
    onClose: () => void;
    defaultFaq?: IFAQ;
    isEditing?: boolean;
}
const FaqForm = ({ openModal, onClose, defaultFaq, isEditing }: CreateModal) => {
    const [topic, setTopic] = useState<IFAQ>({
        topic: '',
        questions: [],
    });

    useEffect(() => {
        if (isEditing && defaultFaq) {
            setTopic(defaultFaq);
        }
    }, [isEditing, defaultFaq])


    const addQuestion = () => {
        const data: any = topic;
        if (data) data.questions.push({ question: '', answer: '', url: '' });
        setTopic({ ...data });
    };

    const deleteQuestion = (index: number) => {
        const data: any = topic;
        if (data) data.questions.splice(index, 1);
        setTopic({ ...data });
    };

    const handleInputChange = (prop: string, value: string, index?: number) => {
        const data: any = topic;
        if (data && typeof index !== 'undefined') data.questions[index][prop] = value ? value : '';
        if (data && !index) data[prop] = value ? value : '';
        setTopic({ ...data });
    };

    const saveChanges = async () => {
        if(isEditing){
            await request('/api/faq/edittopic', {
                body: JSON.stringify(topic),
                method: 'PUT'
            }).then(() => {
                Swal.fire('Éxito!', 'Se ha actualizado con éxito', 'success');
                onClose();
            }).catch((e:unknown) => {
                Swal.fire('Hubo un error al actualizar', (e as Error).message, 'error');
            });
        }else{
            await request('/api/faq/createtopic', {
                body: JSON.stringify(topic),
                method: 'POST'
            }).then(() => {
                Swal.fire('Éxito!', 'Se ha agregado con éxito', 'success');
                onClose();
            }).catch((e:unknown) => {
                Swal.fire('Hubo un error al crear', (e as Error).message, 'error');
            });
        }
    }
    return (
        <div className="container">
            <Modal onHide={onClose} title="Crear tópico" show={openModal} footer={null}>
                <Form onSubmit={saveChanges} className="form m-4">
                    <Form.Group>
                        <Form.Label>Tópico:</Form.Label>
                        <Form.Control
                            name="topic"
                            onChange={(e) => handleInputChange('topic', e.target.value)}
                            defaultValue={topic.topic}
                        />
                    </Form.Group>

                    <Form.Group>
                        {
                            topic?.questions.map((item, index) => (
                                <Row className="m-3" >
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Pregunta:</Form.Label>
                                            <Form.Control
                                                name="question"
                                                onChange={(e) => handleInputChange('question', e.target.value, index)}
                                                defaultValue={item.question ? item.question : ''}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Respuesta:</Form.Label>
                                            <Form.Control
                                                name="answer"
                                                onChange={(e) => handleInputChange('answer', e.target.value, index)}
                                                defaultValue={item.answer ? item.answer : ''}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Url:</Form.Label>
                                            <Form.Control
                                                name="url"
                                                onChange={(e) => handleInputChange('url', e.target.value, index)}
                                                defaultValue={item.url ? item.url : ''}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="m-3">
                                            <Button variant="danger" onClick={() => deleteQuestion(index)}>Eliminar</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            ))
                        }
                    </Form.Group>
                    <Row className="m-3" >
                        <Col xs={4}>
                            <Form.Group>
                                <Button variant='success' onClick={() => addQuestion()}>Agregar Pregunta</Button>
                            </Form.Group>
                        </Col>
                        <Col xs={4}>
                            <Form.Group>
                                <Button key="create" type="submit" disabled={!topic.topic}>
                                    {isEditing ? 'Actualizar' : 'Crear'}
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col xs={4}>
                            <Form.Group>
                                <Button onClick={onClose}>
                                    Cancelar
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default FaqForm;