import React, { useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { ResponseResult } from '../../types';
import { request } from '../../services/Request';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';

function UsersUpload() {

	const [selectedFiles, setFiles]: any = useState([]);

	const onDrop = (acceptedFiles: any) => {
		let files = selectedFiles;
		files = acceptedFiles;
		setFiles(files);
	};

	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		onDrop: onDrop,
		accept: {
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
			'application/vnd.ms-excel': ['.xlsx']
		}
	});

const upload = () => {
	if (selectedFiles.length > 0) {
		Swal.fire('Subir usuarios', 'Cargando...');
		Swal.showLoading();
		const data = new FormData();
		for (const file of selectedFiles) {
			data.append('files', file);
		}
		request('/api/user/upload', {
			method: 'POST',
			file: true,
			body: data
		})
			.then((result: ResponseResult) => {
				Swal.close();
				if (result.data) {
					Swal.fire('Subir usuarios', 'Los usuarios se subieron correctamente', 'success');
				} else {
					Swal.fire('Subir usuarios', 'Ocurrió un error', 'error');
				}
			})
	}
}

	return (
        <div id="upload"  className="content list">
            <Row>
                <Col xs={24} md={{span:8,offset:2}}>
                    <Card className="mt-0 mt-lg-5 p-3">
                        <Card.Body>
                            <h3>Subir usuarios por excel</h3>                        
                            <a href="/plantilla_usuarios.xlsx" className="mb-3">Descargar plantilla</a>
                            <div className='file-drop justify-center mt-5' {...getRootProps()}>
                                <input {...getInputProps({multiple:false})} />
                                <p className="text-center mb-4">Arrastra y suelta tus archivo o presiona aquí para elegirlo.</p> 
                            </div>
                            <p className='mt-3'><b>Archivo seleccionado:</b></p>
                            <ul className="mb-5">
                                {
                                    selectedFiles.map((file:any,key:number)=>(
                                        <li key={key}>
                                            { file.name }
                                        </li>
                                    ))
                                }
                            </ul>
                            <Button className="btn btn-primary" disabled={selectedFiles.length <= 0 } onClick={upload}>Subir archivo</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default UsersUpload;