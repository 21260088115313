import { Whatsapp } from 'react-bootstrap-icons';
import '../styles/footer.scss';
import Ribbon from './Ribbon';

function Footer(){    
    return (
        <>
            <Ribbon/>
            <div className="footer">
                <p>
                    Si presentas alguna dificultad con la plataforma, escríbenos a: <b>alejandra.medina@designamx.com</b> o Manda mensaje de WhatsApp al sigueinte numero: <b>8180915876 <Whatsapp/></b>
                </p>
            </div>
        </>
    )
}

export default Footer;