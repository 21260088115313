import React, { useState } from 'react';
import { Form, Button, Card, Row, Col, Modal } from 'react-bootstrap';
import '../styles/login.scss';
import Swal from 'sweetalert2';
import { request } from '../services/Request';
import { ResponseResult } from '../types';

type LoginData = {
	email: string;
	password: string;
	[key: string]: any;
}

function LoginPage() {

	const [loginData, setLoginData] = useState<LoginData>({
		email: '',
		password: ''
	});
	const [showRecover, setRecover] = useState(false);
	const [email, setEmail] = useState('');

	const onFinish = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		try {
			Swal.fire("Iniciar sesión", "Cargando..");
			Swal.showLoading();
			const response = await fetch('/api/auth/signin', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(loginData)
			});
			if (response.status !== 500) {
				const data: any = await response.json();
				Swal.close();
				if (data.token) {
					localStorage.token = data.token;
					window.location.href = "/";
				} else {
					Swal.fire("Iniciar sesión", "Datos incorrectos");
				}
			} else {
				Swal.fire("Iniciar sesión", "Cargando..");
			}
		} catch (e: unknown) {
			Swal.fire("Iniciar sesión", "Cargando..");
		}
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const data = loginData;
		data[e.target.name] = e.target.value;
		setLoginData(data);
	}

	const emailHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	}

	const confirmRecover = () => {
		Swal.fire('Enviando petición...');
		Swal.showLoading();
		request('/api/auth/request', {
			method: 'POST',
			body: JSON.stringify({
				email: email
			})
		})
			.then((result: ResponseResult) => {
				setRecover(false);
				Swal.hideLoading();
				if (result.data) {
					Swal.fire("Recuperar contraseña", 'Se ha realizado la petición con éxito. En unos momentos llegará a tu correo un enlace para continuar el proceso.', 'success');
				} else if (result.error.status == 420) {
					Swal.fire("Recuperar contraseña", 'No hay un usuario registrado con ese correo', 'error');
				} else {
					Swal.fire("Recuperar contraseña", 'Ocurrió un error intentado enviar la petición', 'error');
				}
			})
			.catch(() => {
				Swal.fire("Recuperar contraseña", 'Ocurrió un error intentado enviar la petición', 'error');
			});
	}

    return (
        <div className="login content">
            <Row>
                <Col xs={{span:12}} md={{span:6, offset:3}}>
                    <h2 className="mt-lg-5 mb-3">Iniciar sesión</h2>
                    <Card className="p-5">
                        <Card.Body>
                            <Form onSubmit={onFinish}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Correo Electrónico</Form.Label>
                                    <Form.Control type="email" name='email' placeholder="Correo electrónico" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control type="password" name='password' placeholder="Contraseña" onChange={onChange}/>
                                </Form.Group>
                                <Row className="pt-3">
                                    <Col xs={12} md={{span:6,offset:3}}>
                                        <button type="button" className="mt-3 mb-3 ps-0 btn btn-link w-100" onClick={()=>{setRecover(true)}}>Recuperar contraseña</button>
                                    </Col>
                                    <Col xs={12} md={{span:6,offset:3}}>
                                        <Button variant="primary" type="submit" className="w-100">
                                            Ingresar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={showRecover} onHide={()=>{setRecover(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Recuperar contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="email">
                        <Form.Label>
                            <p>Ingrese su correo electrónico y enviaremos un correo con la
                            solicitud para la recuperación de contraseña.</p>
                        </Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={emailHandle}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setRecover(false)}} className='basic-button basic-button-cancel'>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={confirmRecover} className='basic-button'>
                        Solicitar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default LoginPage;
