export const request = (url: string, options: any) => {
  function handleErrors(response: any) {
    if (response.status === 403 && !options.noRedirect) {
      delete localStorage.user;
      delete localStorage.token;
      window.location.href = "/login";
    } else if (!response.ok) {
      return response.text().then((text: any) => {
        response.message = text
        throw response;
      });
    }
    return response;
  }
  if (localStorage.token) {
    if (!options.headers) {
      options['headers'] = {
        'Authorization': "Bearer " + localStorage.token
      }
    } else if (!options.headers['Authorization']) {
      options['headers']['Authorization'] = "Bearer " + localStorage.token;
    }
  }

  if (options['method'] === 'POST' && !options["file"]) {
    options["headers"]["Content-Type"] = "application/json";
  }
  if (options['method'] === 'DELETE' || options['method'] === 'PUT') {
    options["headers"]["Content-Type"] = "application/json";
  }

  return fetch(url, options)
    .then(handleErrors)
    .then(response => {
      return response
    })
    .then((response) => {
      return response.json()
        .then((data: unknown) => {
          return { data, status: response.status }
        })
    })
    .catch(
      (error) => {
        return { error: error, status: error.status };
      }
    )
}
