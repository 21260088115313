import { Table } from 'react-bootstrap';
import { IFAQ } from '../types';

type PropsFaqTable = {
    faq: IFAQ;
}


export const FaqTable = ({ faq }: PropsFaqTable) => {

    return (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th>Pregunta</th>
                    <th>Respuesta</th>
                    <th>Url</th>
                </tr>
            </thead>
            <tbody>
                {
                    faq.questions.map(({ question, answer, url }, j) => (
                        <tr key={j}>
                            <td>{question}</td>
                            <td>{answer}</td>
                            <td>{url}</td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
}
