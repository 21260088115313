import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

/* type RouteParams = {
    token: string;
} */

function TeacherConfirmation(){

    const { token } = useParams();

    useEffect(()=>{
        confirmUser();
    }, []);

    const confirmUser = async () => {
        try{
            Swal.fire("Confirmación", "Cargando...");
            Swal.showLoading();
            const result = await request("/api/user/confirm", {
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            });
            Swal.close()
            if(result.data){
                Swal.fire("Confirmación","El proceso de registro se ha completado. Al cerrar esta ventana será redirigido a la pantalla de iniciar sesión","success")
                .then(()=>{
                    window.location.href = "/login";
                });
            }else{
                Swal.fire("Confirmación","Ocurrió un error al cargar la confirmación","error");
            }
        }catch(e:unknown){
            Swal.fire("Confirmación","Ocurrió un error al cargar la confirmación","error");
        }
    }

    return (
        <div></div>
    )
}

export default TeacherConfirmation;