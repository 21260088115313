import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Home from "./pages/Home";
import QuestionnaireList from "./pages/questionnaires/QuestionnaireList";
import QuestionnaireNew from "./pages/questionnaires/QuestionnaireNew";
import QuestionnaireEdit from "./pages/questionnaires/QuestionnaireEdit";
import QuestionnaireAnswer from "./pages/questionnaires/QuestionnaireAnswer";
import UserList from './pages/users/UserList';
import UserNew from './pages/users/UserNew';
import ConfirmPassword from './pages/auth/ConfirmPassword';
import UserEdit from './pages/users/UserEdit'
import UsersUpload from './pages/users/UsersUpload';
import StudentsList from "./pages/students/StudentsList";
import StudentQuestionnaireSign from "./pages/students/StudentQuestionnaireSign";
import StudentEdit from "./pages/students/StudentEdit";
import TeacherRegister from "./pages/teachers/TeacherRegister";
import TeacherConfirmation from "./pages/teachers/TeacherConfirmation";
import Header from './components/Header';
import Footer from './components/Footer';
import "./App.scss"
import { Container } from 'react-bootstrap';
import ProtectedRoute from "./services/ProtectedRoute";
import SessionContext, { initialSession } from './context/Session';
import { getSession } from './services/Session.service';
import { useState, useEffect } from 'react';
import SchoolList from "./pages/schools/SchoolList";
import SchoolNew from "./pages/schools/SchoolNew";
import SchoolUpload from "./pages/schools/SchoolUpload";
import AllStudentsList from "./pages/students/AllStudentsList";
import StudentsUpload from "./pages/students/StudentsUpload";
import StudentNew from "./pages/students/StudentNew";
import SchoolEdit from "./pages/schools/SchoolEdit";
import Reports from "./pages/Reports";
import Answers from "./pages/Answers";
import Numerals from "./pages/Numerals";
import AppConfig from "./pages/AppConfig";
import FaqListAdmin from "./pages/faq/FaqListAdmin";
import FaqListPublic from "./pages/faq/FaqListPublic";

function App() {

  const [session, setSession] = useState({ loading: true, data: initialSession });
  //const [isLogged, setLogged] = useState(false);
  //const sessionVerifier = useContext(Session);
  //const [role, setRole] = useState(4);

  /* useEffect(() => {
    if (sessionVerifier._id != '' || sessionVerifier._id != undefined || sessionVerifier._id != null) {
      setLogged(true);      
    }
  }, [sessionVerifier._id]); */

  useEffect(() => {
    async function getSessionAsync() {
      const { data } = await getSession();
      if(data){
        //console.log(1);
        setSession({ loading: false, data });
      }else{
        //console.log(2);
        setSession({ loading: false, data: initialSession })
      }
    }
    getSessionAsync();
  }, []);

  return (
    <SessionContext.Provider value={session.data}>
      <Container fluid className="p-0">
        <Header></Header>
        <BrowserRouter>
          {
            !session.loading && (
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="confirmation/:token" element={<ConfirmPassword />} />
                <Route path="docentes/confirmacion/:token" element={<TeacherConfirmation />} />
                <Route path="diagnostico" element={<StudentQuestionnaireSign />} />
                <Route path="diagnostico/registro" element={<StudentQuestionnaireSign />} />
                <Route path="docentes/registro" element={<TeacherRegister />} />
                <Route path="responder/:slug" element={<QuestionnaireAnswer />} />
                <Route path="questionnaires/edit/:slug" element={<ProtectedRoute><QuestionnaireEdit /></ProtectedRoute>} />
                <Route path="questionnaires/new" element={<ProtectedRoute><QuestionnaireNew /></ProtectedRoute>} />
                <Route path="questionnaires" element={<ProtectedRoute><QuestionnaireList /></ProtectedRoute>} />
                <Route path="users/edit/:id" element={<ProtectedRoute><UserEdit /></ProtectedRoute>} />
                <Route path="students" element={<ProtectedRoute><StudentsList /></ProtectedRoute>} />
                <Route path="students/list" element={<ProtectedRoute><AllStudentsList /></ProtectedRoute>} />
                <Route path="students/upload" element={<ProtectedRoute><StudentsUpload /></ProtectedRoute>} />
                <Route path="students/new" element={<ProtectedRoute><StudentNew /></ProtectedRoute>} />
                <Route path="students/edit/:id" element={<ProtectedRoute><StudentEdit /></ProtectedRoute>} />
                <Route path="users/new" element={<ProtectedRoute><UserNew /></ProtectedRoute>} />
                <Route path="users/upload" element={<ProtectedRoute><UsersUpload /></ProtectedRoute>} />
                <Route path="users" element={<ProtectedRoute><UserList /></ProtectedRoute>} />
                <Route path="schools/new" element={<ProtectedRoute><SchoolNew /></ProtectedRoute>} />
                <Route path="schools/edit/:id" element={<ProtectedRoute><SchoolEdit /></ProtectedRoute>} />
                <Route path="schools/upload" element={<ProtectedRoute><SchoolUpload /></ProtectedRoute>} />
                <Route path="schools" element={<ProtectedRoute><SchoolList /></ProtectedRoute>} />
                <Route path="reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
                <Route path="answers" element={<ProtectedRoute><Answers /></ProtectedRoute>} />
                <Route path="numerals" element={<ProtectedRoute><Numerals /></ProtectedRoute>} />
                <Route path="faqs" element={<ProtectedRoute><FaqListAdmin /></ProtectedRoute>} />
                <Route path="public/faqs" element={<FaqListPublic />}/>
                <Route path="config" element={<ProtectedRoute><AppConfig /></ProtectedRoute>} />
              </Routes>
            )
          }
        </BrowserRouter>
        <Footer></Footer>
      </Container>
    </SessionContext.Provider>
  );
}

export default App;
