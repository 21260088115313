import { useState, useEffect } from "react";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import { Questionnaire, ResponseResult, Question, RouteParam } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import '../../styles/form.scss';
import { Input, Checkbox, Select, Radio, InputNumber, InputDate, Lecture } from '../../components/FormComponents';
import { useParams } from 'react-router-dom';
import Image from '../../assets/90 minutos.png';
//import headerJalisco from '../../assets/headerJalisco.png';
import { ArrowLeft, ArrowRight, SendFill } from 'react-bootstrap-icons';
import headerGTO from '../../assets/logos/logo-SEG-02.png';
import * as Sentry from "@sentry/react";

export const QuestionnaireAnswer = () => {

	const [userData, setData]: any = useState({});
	const [formData, setFormData] = useState<Partial<Questionnaire>>({});
	const [finalData, setFinalData] = useState({});
	const [currentSection, setCurrentSection] = useState(0);
	const [validated, setValidated] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [redirectTo, setRedirect] = useState(-2);
	const [showLastStep, setLastStep] = useState(false);
	const [sectionHistory, setSectionHistory] = useState<number[]>([]);
	const { slug } = useParams<RouteParam>();
	const [firstStep, setFirstStep] = useState(true);
	const [student, setStudent] = useState({
		_id: ''
	});

	const isQuestionnaireOpen = false;
	const element = document.getElementById('formId');
	const headerOffset = 150;
	const elementPosition = element?.getBoundingClientRect().top;
	const offsetPosition = elementPosition ? elementPosition + window.pageYOffset - headerOffset : 0;
	const iconSize = 30;


	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		if (params.has('token')) {
			const token = params.get('token') || '';
			getStudent(token);
		} else {
			getQuestionnaire();
		}
	}, []);

	const getStudent = async (token: string) => {
		try {
			Swal.fire("Cuestionario", "Cargando...");
			Swal.showLoading();
			const result = await request("/api/student/me", {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			Swal.close()
			if (result.data) {
				setStudent(result.data);
				getQuestionnaire();
			} else {
				Swal.fire("Cuestionario", "Error al cargar la información del estudiante", "error");
			}
		} catch (e: unknown) {

			Swal.fire("Cuestionario", "Error al cargar", "error");
		}
	}

	const getQuestionnaire = async () => {
		try {
			Swal.fire("Cuestionario", "Cargando...");
			Swal.showLoading();
			const result = await request("/api/questionnaire/public/" + slug, {});
			Swal.close()
			if (result.data) {
				setFormData(result.data);
			} else {
				Swal.fire("Cuestionario", "Error al cargar el cuestionario", "error");
			}
		} catch (e: unknown) {

			Swal.fire("Cuestionario", "Error al cargar", "error");
		}
	}

	const signin = () => {
		setDisableButton(true);
		Swal.fire("Cuestionario", "Guardando datos..");
		Swal.showLoading();
		const sendData = { ...finalData, ...userData };
		const options = {
			method: 'POST',
			body: JSON.stringify({
				fields: sendData,
				questionnaire: formData._id,
				student_id: student ? student._id : undefined
			})
		};
		request(`/api/questionnaire/answer`, options)
			.then((result: ResponseResult) => {
				Swal.close();
				if (result.error) {
					Sentry.captureException(result.error as Error);
					Swal.fire("Cuestionario", 'Ocurrió un error al registrar las respuestas.', 'error');
				} else {
					endSurvey();
				}
				setDisableButton(false);
			})
	}

	const onChange = (e: any) => {
		const data = userData;
		const targetName = e.target.name;
		const element = document.getElementById(e.target.id) as HTMLInputElement;
		const validation = element ? element.getAttribute('data-validate') : null;
		if (e.target.value && e.target.value != '') {
			let value = e.target.value;
			const redirect = document.getElementById(e.target.id)?.getAttribute('data-redirect');
			if (redirect) {
				setRedirect(Number(redirect));
			}
			if (element.type == 'checkbox') {
				const checkboxes = document.getElementsByName(e.target.name) as NodeListOf<HTMLInputElement>;
				const checkboxesChecked = [];
				for (let i = 0; i < checkboxes.length; i++) {
					if (checkboxes[i].checked) {
						checkboxesChecked.push(checkboxes[i].value);
					}
				}
				data[targetName] = checkboxesChecked;

			} else if (validation) {
				if (validation == 'email') {
					value = value.toLowerCase().trim();
				} else if (validation == 'phone') {
					value = value.toLowerCase().trim().replace(/ /g, '').substring(0, 10);
				} else if (validation == 'curp') {
					value = e.target.value.toUpperCase().trim();
				}
				if (element) {
					element.value = value;
				}
				data[targetName] = value;
			} else {
				data[targetName] = value;
			}
		} else {
			delete data[targetName];
		}
		setData(data);
		const storagedData = localStorage.data ? JSON.parse(localStorage.data) : {};
		if (!storagedData[currentSection]) {
			storagedData[currentSection] = {};
		}
		storagedData[currentSection] = data;
		localStorage.data = JSON.stringify(storagedData);
	}

	const validateCurp = (text: string) => {
		const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
		const flag = text.match(regex);
		return flag;
	}

	function removeTags(str: string) {
		if ((str === null) || (str === ''))
			return '';
		else
			str = str.toString();
		return str.replace(/(<([^>]+)>)/ig, '');
	}

	function shortText(str: string) {
		return str.substring(0, 40) + "...";
	}

	const nextSection = () => {
		if (formData.sections) {
			let count_keys = 0;
			let required_keys = 0;
			let curp_count = 0;
			let curp_correct = 0;
			let missing_fields = '<ul class="error-list">';
			for (const question of formData.sections[currentSection].questions) {
				if (!question.optional) {
					required_keys++;
					if (userData[question.name]) {
						count_keys++;
					} else {
						missing_fields += '<li>' + shortText(removeTags(question.title)) + '</li>'
					}
				}
				if (question.type == 'curp') {
					curp_count++;
					if (validateCurp(userData[question.name] ? userData[question.name] : '')) {
						curp_correct++;
					}
				}
			}
			//console.log(count_keys, required_keys);
			if (curp_count != curp_correct) {
				Swal.fire("Formulario", "La CURP ingresada es incorrecta", "error");
			} else if (required_keys == count_keys) {
				setFinalData({ ...finalData, ...userData });
				let index: any = 0;
				if (formData.sections && formData.sections[currentSection].redirect) {
					index = formData.sections && formData.sections[currentSection] && formData.sections[currentSection].redirect ? formData.sections[currentSection].redirect : currentSection + 1;
				} else {
					const lastQuestion: Question = formData.sections[currentSection].questions[formData.sections[currentSection].questions.length - 1];
					if (lastQuestion.options) {
						const selectedOption: any = lastQuestion.options.filter((option: any) => {
							return option.value === userData[lastQuestion.name ? lastQuestion.name : ''];
						});
						index = selectedOption && selectedOption[0] && selectedOption[0].redirect ? selectedOption[0].redirect : currentSection + 1;
					}
				}
				if (currentSection == (formData.sections.length - 1) || index == -1) {
					signin();
				} else {
					let localData: any = {};
					if (localStorage.data) {
						localData = JSON.parse(localStorage.data);
					}
					if (index && localData[index]) {
						setData(localData[index]);
					} else {
						setData({});
					}
					const history = sectionHistory;
					history.push(currentSection);
					setSectionHistory(history);
					setCurrentSection(index);
					setRedirect(-2);
					setValidated(false);
					if (document.getElementById("formId")) {
						window.scrollTo({
							top: offsetPosition,
							behavior: "smooth"
						});
					}
					if (currentSection + 1 == (formData.sections.length - 1) || (index && formData.sections[index].redirect == -1)) {
						setLastStep(true);
					}
				}
			} else {
				setValidated(true);
				Swal.fire({
					title: 'Error formulario',
					html: 'Hay campos sin completar que son requeridos. Requeridos: ' + required_keys + ', Ingresados: ' + count_keys + '.<br/><br/> Campos faltantes<br/>' + missing_fields,
					icon: 'error'
				});
			}
		}
	}

	const prevSection = () => {
		setRedirect(currentSection)
		let localData: any = {};
		if (localStorage.data) {
			localData = JSON.parse(localStorage.data);
		}
		const history = sectionHistory;
		setData(localData[history[history.length - 1]]);
		setCurrentSection(history[history.length - 1]);
		history.pop();
		setSectionHistory(history);
		setLastStep(false);
		if (document.getElementById("formId")) {
			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth"
			});
		}
	}

	const endSurvey = () => {
		Swal.fire({
			title: 'Cuestionario',
			text: 'Tus respuestas se han guardado con éxito. Haz clic en el siguiente botón para completar el proceso.',
			icon: 'success'
		}).then(() => {
			delete localStorage.data;
			delete localStorage.code;
			window.location.href = "/diagnostico"
		})
	}

	return (
		<div className='answer-form content preview'>
			<div className="logos">
				<Row>
					<Col xs={5} style={{ backgroundColor: 'white', borderTopRightRadius: '60px', borderBottomRightRadius: '60px', justifyContent: 'center', alignContent: 'center', marginTop: '40px', marginBottom: '50px' }} className="ps-5 py-2">
						<Row>
							<Col>
								<img className="header-img" src={headerGTO}></img>
							</Col>
						</Row>

					</Col>
				</Row>
			</div>
			{
				isQuestionnaireOpen ? (
					firstStep ? (
						<Row>
							<Col xs={12} md={{ span: 8, offset: 2 }}>
								<div className="introduction">
									<div className="pt-3 pb-3">
										<h2>{formData.name}</h2>
										<div dangerouslySetInnerHTML={{ __html: formData.introduction }}></div>
										<Row className="mt-5 align-items-center">
											<Col xs={6} md={2}>
												<p className="duration-title">Tiempo límite:</p>
												<div className="clock-container">
													<div className="overlay" style={{ alignItems: 'center' }}>
														<p style={{ fontSize: '25px' }} className="mt-3">{formData.duration}</p>
													</div>
													<img src={Image} className="clock"></img>
												</div>
											</Col>
											<Col xs={6} md={8}>
												<h2 className="thanks"><b>¡Muchas gracias!</b><br></br>por tu participación</h2>
											</Col>
										</Row>
									</div>
									<Row className="justify-content-end">
										<Col xs={12} md={4}>
											<Button className="mt-5 w-100 basic-button" variant="primary" onClick={() => setFirstStep(false)}>Comenzar</Button>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					) : (
						<div className="form-content">
							<Row>
								<Col xs={12} md={{ span: 8, offset: 2 }}>
									<Form id="formId" noValidate validated={validated}>
										{
											(formData.sections && formData.sections[currentSection] && formData.sections[currentSection].section) && (
												<h3 className="mb-3 text-center">{formData.sections[currentSection].section}</h3>
											)
										}
										{
											(formData.sections && formData.sections[currentSection] && formData.sections[currentSection].questions[0] && formData.sections[currentSection].questions[0].sectionTitle) && (
												<p className="mb-3 text-center">{formData.sections[currentSection].questions[0].sectionTitle}</p>
											)
										}
										{
											formData.sections && formData.sections[currentSection] && formData.sections[currentSection].questions.map((item: Question, index: number) => (
												<Row className="justify-content-center" key={item.title}>
													<Col xs={12} md={12} className="p-0">
														<Card className="mt-3 rounded-card" id={"card-" + item.name}>
															<Card.Body>
																<Row className="align-items-start">
																	<Col key={currentSection + '-' + index} xs={12} md={12}>
																		{
																			(item.type === 'text' || item.type === 'email' || item.type === 'phone' || item.type === 'curp') && (
																				<Input onChange={onChange} item={item} value={userData[item.name ? item.name : '']}></Input>
																			)
																		}
																		{
																			item.type == 'number' && (
																				<InputNumber onChange={onChange} item={item} value={userData[item.name ? item.name : '']}></InputNumber>
																			)
																		}
																		{
																			item.type == 'date' && (
																				<InputDate onChange={onChange} item={item} value={userData[item.name ? item.name : '']}></InputDate>
																			)
																		}
																		{
																			item.type == 'select' && (
																				<Select onChange={onChange} item={item} value={userData[item.name ? item.name : '']}></Select>
																			)
																		}
																		{
																			item.type == 'radio' && (
																				<Radio onChange={onChange} item={item} value={userData[item.name ? item.name : '']}></Radio>
																			)
																		}
																		{
																			item.type == 'checkbox' && (
																				<Checkbox onChange={onChange} item={{
																					name: item.name,
																					title: item.title,
																					options: item.options,
																				}} value={userData[item.name ? item.name : '']}></Checkbox>
																			)
																		}
																		{
																			item.type == 'description' && (
																				<Lecture item={item}></Lecture>
																			)
																		}
																	</Col>
																</Row>
															</Card.Body>
														</Card>
													</Col>
												</Row>
											))
										}
										<Row className="justify-center">
											<Col xs={12} md={12}>
												<div className="d-flex justify-content-center mt-5">
													{
														currentSection > 0 && (
															<button className="btn btn-primary me-3 basic-button" type="button" onClick={prevSection}><ArrowLeft size={iconSize} /> Regresar</button>
														)
													}
													{
														(!showLastStep && redirectTo != -1) ? (
															<button className="btn btn-primary basic-button" type="button" onClick={nextSection}>Siguiente <ArrowRight size={iconSize} /></button>
														) : (
															<button className="btn btn-primary basic-button" type="button" onClick={nextSection} disabled={disableButton}>Enviar <SendFill size={iconSize} /></button>
														)
													}
												</div>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						</div>
					)
				) : (
					<div>
						<p>Cuestionario cerrado</p>
					</div>
				)
			}
		</div>
	);
}


export default QuestionnaireAnswer;
