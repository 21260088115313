import { Row, Col } from 'react-bootstrap';
import '../styles/login.scss';
import Image1 from '../assets/home.jpg';
import Image2 from '../assets/Doc.png';
import Image3 from '../assets/logos/logo-SEG-01.png';
import Image4 from '../assets/logos/logo-SEG-04.png';
import Image5 from '../assets/logos/logo-SEG-03.png';

function Home() {

    return (
        <div className="home">
            <Row>
                <Col xs={{span:12}} md={{span:8}}>
                    <div className="left">
                        <img src={Image1} className="image-1"></img>
                        <div className="overlay">
                            <Row>
                                <Col xs={{offset:1,span:10}} md={{offset:2, span:4}} lg={{offset:2, span:3}}>
                                    <a className="btn btn-blue" href="/questionnaires/new">Comenzar</a>
                                </Col>
                            </Row>
                        </div>
                        <img src={Image3} className="image-3" alt="SEG" />
                    </div>
                </Col>
                <Col xs={{span:12}} md={{span:4}} className="right-container">
                    <div>
                        <div className="d-none d-sm-none d-md-block">
                            <h1>¿Necesitas ayuda?</h1>
                            <Row>
                                <Col xs={12} md={{span: 10, offset: 1 }} lg={{span:8,offset:2}}>
                                    <a href={'/Guia_uso_cuestionarios.pdf'} target={"_blank"}><img src={Image2} className="image-1"></img></a>
                                    <p className="text-justify">Te brindamos una breve <a href={'/Guia_uso_cuestionarios.pdf'} target={"_blank"}><b>guía</b></a> para usar la plataforma</p>
                                    <div className='images-right d-flex flex-column align-items-center justify-content-end'>
                                        <img src={Image4} alt="SEG" className='image-4' />
                                        <img src={Image5} alt="SEG" className='image-5' />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="d-block d-md-none d-lg-none p-2">
                            <p className="text-justify"><b>¿Necesitas ayuda?</b> Consulta la <b>guía</b> de uso <a href={'/Guia_uso_cuestionarios.pdf'} target={"_blank"}>aquí</a></p>
                            <img src={Image4} alt="SEG" className='image-4' />
                            <img src={Image5} alt="SEG" className='image-5' />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Home;
