import { useState, useEffect } from 'react';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { Questionnaire } from '../../types';
import { Row, Col } from 'react-bootstrap';
import Image1 from '../../assets/BG.png';
import '../../styles/questionnaires.scss';
import QuestionnaireCard from '../../components/QuestionnaireCard';
import { FileEarmarkBarGraph, PencilSquare, XSquare, Search } from 'react-bootstrap-icons'


function QuestionnaireList() {

	const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);

	useEffect(() => {
		getQuestionnaires();
	}, []);

	const getQuestionnaires = async () => {
		try {
			Swal.fire("Cuestionarios", "Cargando...");
			Swal.showLoading();
			const result = await request('/api/questionnaire', {});
			Swal.close();
			if (result.data) {
				setQuestionnaires(result.data);
			} else {
				Swal.fire("Cuestionarios", "Ocurrió un error al cargar los datos", "error");
			}
		} catch (e: unknown) {
			Swal.fire("Cuestionarios", "Ocurrió un error al cargar los datos", "error");
		}
	}

    /* const toggle = (item: Questionnaire) => {
        Swal.fire({
            title: item.status ? 'Desactivar cuestionario' : 'Activar custionario',
            text: `¿Estás seguro de cambiar el estatus del cuestionario ${item.name}?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        })
        .then(async (result:any)=>{
            if(result.isConfirmed){
                Swal.fire(item.status ? 'Desactivar cuestionario' : 'Activar custionario', "Realizando petición..");
                Swal.showLoading()
                const result = await request('/api/questionnaire/toggle/'+item._id,{
                    method: 'PUT',
                    body: JSON.stringify({
                        status: !item.status
                    })
                });
                if(result.data){
                    Swal.fire(item.status ? 'Desactivar cuestionario' : 'Activar custionario',"Se ha cambiado el estatus con exito","success");
                    getQuestionnaires();
                }else{
                    Swal.fire(item.status ? 'Desactivar cuestionario' : 'Activar custionario',"Ocurrió un error al realizar el proceso","error");
                }
            }
        })
    } */

	return (
		<div className="list">
			{
				questionnaires.length > 0 ? (
					<Row>
						<Col xs={12} md={{ offset: 1, span: 10 }}>
							<div className="content">
								<Row>
									<Col xs={12}>
										<h2>Tu actividad reciente</h2>
										<div className="d-block d-sm-block d-md-none">
											<Row>
												<Col className='edit' xs={3}>
													<PencilSquare className="indicator" />
													<p className="text-option">Editar</p>
												</Col>
												<Col className='delete' xs={3}>
													<XSquare className="indicator" />
													<p className="text-option">Eliminar</p>
												</Col>
												<Col className='preview-button' xs={3}>
													<Search className="indicator" />
													<p className="text-option">Vista previa</p>
												</Col>
												<Col className='report' xs={3}>
													<FileEarmarkBarGraph className="indicator" />
													<p className="text-option">Reporte</p>
												</Col>
											</Row>
										</div>
									</Col>
									{
										questionnaires.map((questionnaire: Questionnaire, key: number) => (
											<Col xs={12} md={4} lg={3} key={key} className="ps-0">
												<QuestionnaireCard questionnaire={questionnaire} callback={getQuestionnaires}></QuestionnaireCard>
											</Col>
										))
									}
									<Col xs={12}>
										<Row style={{ justifyContent: 'center' }}>
											<Col xs={12} md={6} lg={4}>
												<a className="btn btn-blue mt-5 basic-button" href={"/questionnaires/new"}>Crear nuevo cuestionario</a>
											</Col>
										</Row>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				) : (
					<div className="content">
						<Row>
							<Col xs={12}>
								<Row className="align-items-center">
									<Col xs={12} md={{ offset: 2, span: 6 }}>
										<h1>Aún no has guardado ningún cuestionario</h1>
										<Row>
											<Col xs={12} md={5}>
												<a href={"/questionnaires/new"} className="btn btn-blue">Comenzar</a>
											</Col>
										</Row>
									</Col>
									<Col xs={12} md={{ span: 3 }} xxl={2}>
										<img src={Image1} className="image d-none d-sm-none d-md-block"></img>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col xs={12} className="help">
								<div className="d-block d-md-none d-lg-none p-2">
									<p className="text-justify"><b>¿Necesitas ayuda?</b> Consulta la <b>guía</b> de uso <a href="#">aquí</a></p>
								</div>
							</Col>
						</Row>
					</div>
				)
			}
		</div>
	)
}

export default QuestionnaireList;