import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ResponseResult, RouteParam } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function ConfirmPassword() {

    const [loginData, setLoginData]:any = useState({
        password:'',
        password2:''
    });
    const { token } = useParams<RouteParam>();

    useEffect(()=>{
        Swal.fire("Usuario","Verificando usuario...");
        Swal.showLoading();
        request('/api/auth/me',{
            headers:{
                'Authorization': 'Bearer '+token
            },
            noRedirect:true
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(!result.data){
                Swal.fire("Error", "Ocurrió un error al verificar tu usuario", "error");
            }
        }).catch(()=>{
            Swal.fire("Error", "Ocurrió un error al verificar tu usuario", "error");
        })
    },[]);

    const onSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if(loginData.password != loginData.password2){
            Swal.fire("Establecer contraseña","Las contraseñas no coinciden.","error");
        }else{
            Swal.fire("Establecer contraseña", "Cargando...");
            Swal.showLoading();
            request('/api/auth/setpassword',{
                method: 'POST',
                body: JSON.stringify(loginData),
                headers:{
                    'Authorization': 'Bearer '+token
                },
                noRedirect:true
            })
            .then((result:ResponseResult)=>{
                if(result.data){    
                    Swal.fire('Establecer contraseña', 'Tu contraseña se ha establecido, ahora puedes iniciar sesión en la plataforma.', 'success')
                    .then(()=>{
                        //console.log(result);
                        window.location.href = "/"; 
                    })
                }else if(result.error.status == 420){
                    Swal.fire("Iniciar sesión", "Datos incorrectos");
                }else{
                    Swal.fire("Iniciar sesión", "Ocurrió un error al iniciar sesión");
                }
            }).catch(()=>{
                Swal.fire("Iniciar sesión", "Ocurrió un error al iniciar sesión");
            })
        }
    }

    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const data = loginData;
        data[e.target.name] = e.target.value;
        setLoginData(data);
    }

	return (
		<div id="login-container" className="content">
			<Row className="w-100">
				<Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
					<h2 className="mt-5 mb-3">Establecer nueva contraseña</h2>
					<Card className='rounded-card'>
						<Card.Body>
							<Form onSubmit={onSubmit}>
								<Form.Group className="mb-3" controlId="password">
									<Form.Label><b>Ingresar nueva contraseña</b></Form.Label>
									<Form.Control type="password" name="password" placeholder="" onChange={onChange} className='field-styled' />
								</Form.Group>
								<Form.Group className="mb-3" controlId="password2">
									<Form.Label><b>Repetir contraseña</b></Form.Label>
									<Form.Control type="password" name="password2" placeholder="" onChange={onChange} className='field-styled' />
								</Form.Group>
								<div className="d-flex justify-content-center">
									<Button variant="primary" type="submit" className='basic-button'>
										Guardar
									</Button>
								</div>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	)
}

export default ConfirmPassword;