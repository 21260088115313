import { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import { IReport, Questionnaire } from '../types';
import Swal from 'sweetalert2';
import { request } from '../services/Request';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';

function Reports() {

  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
  const [reportData, setReportData]: any = useState<Partial<IReport>>({});
  const [reportType, setReportType] = useState('');
  const [startTime, setStartTime]: any = useState(null);
  const [endTime, setEndTime]: any = useState(null);


  useEffect(() => {
    getQuestionnaires();
  }, []);

  const getQuestionnaires = async () => {
    try {
      Swal.fire("Reportes", "Cargando información...");
      Swal.showLoading();
      const result = await request('/api/questionnaire', {});
      Swal.close();
      if (result.data) {
        setQuestionnaires(result.data);
      } else {
        Swal.fire("Cuestionarios", "Ocurrió un error al cargar los datos", "error");
      }
    } catch (e: unknown) {
      Swal.fire("Cuestionarios", "Ocurrió un error al cargar los datos", "error");
    }
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!reportData['report_type']) {
      Swal.fire("Error", "Es necesario seleccionar el tipo de reporte", "error")
    } else {
      if (reportType === 'complete' || reportType === 'date-range') {
        if (!reportData['questionnaire_id']) {
          Swal.fire("Error", "Es necesario seleccionar una prueba", "error");
        } else if (!reportData['report_type']) {
          Swal.fire("Error", "Es necesario seleccionar el tipo de reporte", "error")
        } else {
          if (reportType === 'date-range') {
            if (!isValidDate(reportData['start_date']) || !isValidDate(reportData['end_date'])) {
              Swal.fire("Error", "La fecha de inicio y/o la fecha de fin no son válidas.", "error")
            } else {
              sendResults();
            }
          } else {
            sendResults();
          }
        }
      } if(reportType === 'diagnostic-stats'){
        if (!reportData['year']) {
          Swal.fire("Error", "Es necesario seleccionar un año", "error");
        }else{
          sendResults();
        }
      }else {
        sendResults();
      }
    }
  }

  const onChange = (e: any) => {
    const data = reportData;
    data[e.target.name] = e.target.value;
    setReportData(data);
    setReportType(data.report_type);    
  }

  const isValidDate = (dateString: string) => {
    // Expresión regular para verificar el formato de fecha en formato 'YYYY-MM-DD'
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
  };

  const sendResults = () => {
    try {
      Swal.fire({
        title: 'Enviar resultados',
        text: `¿Confirmas que deseas enviar los resultados del cuestionario a tu correo electrónico?`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No'
      })
        .then(async (result: any) => {
          if (result.isConfirmed) {            
            const data = {
              ...reportData, 
              start_time: startTime, 
              end_time: endTime
            };            
            Swal.fire("Enviar resultados", "Realizando petición..");
            Swal.showLoading();
            const result = await request("/api/questionnaire/report",{
              method: "POST",
              body: JSON.stringify(data)
            });           
            if (result.data) {
              Swal.fire("Enviar resultados", "¡Petición exitosa! En unos minutos recibirás el correo con la información solicitada. ¡Gracias por tu paciencia!", "success");
            } else {
              Swal.fire("Enviar resultados", "Ocurrió un error al realizar el proceso", "error");
            }
          }
        })
    } catch (e: unknown) {
      Swal.fire("Error", "Ha ocurrido un error al enviar el reporte", "error")
    }
  }

  return (
    <div id="reports" className="content">
      <Row className='mt-lg-5'>
        <Col xs={12} md={{span:10,offset:1}} lg={12}>
          <h2>Reportes</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{span:10,offset:1}} className='mt-2'>
          <Alert variant='info'>
            <p style={{ marginBottom: '0px', textAlign: 'center' }}><b>¡Importante! Es probable que el reporte descargado contenga datos duplicados, los cuales serán eliminados semanalmente para mantener la integridad de la información.</b></p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <Card className='mt-5 p-3'>
            <Card.Body>
              <Form onSubmit={submit}>
                <Form.Group className="mb-3">
                  <Form.Label><b>Tipo de reporte</b></Form.Label>
                  <Form.Select aria-label="Tipo de pregunta" name="report_type" onChange={onChange} className='field-styled'>
                    <option></option>
                    <option value="complete">Reporte completo</option>
                    <option value="date-range">Reporte por fechas</option>
                    <option value="date-range-all">Reporte corto completo: 1°, 2°, 3°</option>
                    <option value="diagnostic-stats">Reporte Diagnostico Con Respuestas</option>
                  </Form.Select>
                </Form.Group>
                {
                  reportType === 'complete' && (
                    <Form.Group className="mb-3">
                      <Form.Label><b>Prueba</b></Form.Label>
                      <Form.Select aria-label="Tipo de pregunta" name="questionnaire_id" onChange={onChange} className='field-styled'>
                        <option></option>
                        {
                          questionnaires.map((questionnaire: Questionnaire, index: number) => (
                            <option value={questionnaire._id} key={'questionnaire_' + index}>{questionnaire.name}</option>
                          ))
                        }
                      </Form.Select>
                    </Form.Group>
                  )
                }
                {
                  reportType === 'date-range' && (
                    <Form.Group className="mb-3">
                      <Form.Label><b>Prueba</b></Form.Label>
                      <Form.Select aria-label="Tipo de pregunta" name="questionnaire_id" onChange={onChange} className='field-styled'>
                        <option></option>
                        {
                          questionnaires.map((questionnaire: Questionnaire, index: number) => (
                            <option value={questionnaire._id} key={'questionnaire_' + index}>{questionnaire.name}</option>
                          ))
                        }
                      </Form.Select>
                    </Form.Group>
                  )
                }
                {
                  reportType && reportType === 'date-range' && (
                    <>
                      <Form.Group>
                        <Row className='justify-content-center'>
                          <Row>
                            <Col xs={12} className='mb-3'>
                              <Alert variant='info'>
                                <p style={{ marginBottom: '0px', textAlign: 'center' }}><b>Importante: Por favor, asegúrate de establecer las horas en formato de 24 horas.</b></p>
                              </Alert>
                            </Col>
                          </Row>
                          <Col xs={12} md={6} lg={5}>
                            <Row>
                              <Col xs={12} className='mb-2'>
                                <Form.Label>Fecha de inicio</Form.Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <Form.Group controlId={"start_date"}>
                                  <Form.Control type="date" name={'start_date'} onChange={onChange} className='field-styled' />
                                  <Form.Control.Feedback type="invalid">Es necesario llenar este campo</Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={6} lg={3}>
                            <Row>
                              <Col xs={12} className='mt-4 mt-md-0 mb-2'>
                                <Form.Label>Hora de inicio</Form.Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <TimePicker
                                  onChange={setStartTime}
                                  value={startTime}
                                  clearIcon={null}
                                  disableClock
                                  format="HH:mm"
                                  hourPlaceholder="HH"
                                  minutePlaceholder="mm"
                                  className='time-picker'
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className='justify-content-center mt-4'>                          
                          <Col xs={12} md={6} lg={5}>
                            <Row>
                              <Col xs={12} className='mb-2'>
                                <Form.Label>Fecha de fin</Form.Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <Form.Group controlId={"end_date"}>
                                  <Form.Control type="date" name={'end_date'} onChange={onChange} className='field-styled' />
                                  <Form.Control.Feedback type="invalid">Es necesario llenar este campo</Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={6} lg={3}>
                            <Row>
                              <Col xs={12} className='mt-4 mt-md-0 mb-2'>
                                <Form.Label>Hora de fin</Form.Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <TimePicker
                                  onChange={setEndTime}
                                  value={endTime}
                                  clearIcon={null}
                                  disableClock
                                  format="HH:mm"
                                  hourPlaceholder="HH"
                                  minutePlaceholder="mm"
                                  className='time-picker'
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form.Group>
                    </>
                  )
                }
                {
                  reportType === 'diagnostic-stats' && (
                    <Form.Group className="mb-3">
                      <Form.Label><b>Seleccione el año</b></Form.Label>
                      <Form.Select aria-label="Año" name="year" onChange={onChange} className='field-styled'>
                        <option></option>
                        <option value='1' key='1'>1</option>
                        <option value='2' key='2'>2</option>
                        <option value='3' key='3'>3</option>
                      </Form.Select>
                    </Form.Group>
                  )
                }
                <Row className="mt-5 justify-right">
                  <Col xs={12} md={4}>
                    <a className="ml-3 btn btn-danger w-100" href="/">
                      Cancelar
                    </a>
                  </Col>
                  <Col xs={12} md={5} lg={4}>
                    <Button variant="primary" type="submit" className="w-100 mt-2 mt-md-0">
                      Generar reporte
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Reports;