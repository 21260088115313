import {useState, useEffect} from "react";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import { Questionnaire, Question } from '../../types';
import { Input, Checkbox, Select, Radio, InputNumber, InputDate, Lecture } from '../../components/FormComponents';
import Image from '../../assets/90 minutos.png';

export const QuestionnairePreview = ({ questionnaire }: { questionnaire: any }) => {

    const [userData, _]:any = useState({});
    const [formData, setFormData] = useState<Partial<Questionnaire>>({
        sections: []
    });
    const [currentSection, setCurrentSection] = useState(0);
    const [validated, setValidated] = useState(false);
    const [showLastStep, setLastStep] = useState(false);
    const [firstStep, setFirstStep] = useState(true);
    const [sectionHistory, setSectionHistory] = useState<number[]>([]);

    useEffect(() => {
        setFormData(questionnaire);
    }, [questionnaire]);

    const nextSection = () => {
        let index: any = 0;
        if (formData.sections && formData.sections[currentSection].redirect) {
            index = formData.sections[currentSection].redirect;
        } else {
            const lastQuestion: Partial<Question> = formData.sections ? formData.sections[currentSection].questions[formData.sections[currentSection].questions.length - 1] : {};
            if (lastQuestion.options) {
                const selectedOption: any = lastQuestion.options.filter((option: any) => {
                    return option.value === userData[lastQuestion.name ? lastQuestion.name : ''];
                });
                index = selectedOption && selectedOption[0] && selectedOption[0].redirect ? selectedOption[0].redirect : currentSection + 1;
            }
        }
        let nextIndex: any = 0;
        if (formData.sections && formData.sections[index].redirect) {
            nextIndex = formData.sections[index].redirect;
        } else {
            const lastQuestion: Partial<Question> = formData.sections ? formData.sections[index].questions[formData.sections[index].questions.length - 1] : {};
            if (lastQuestion.options) {
                const selectedOption: any = lastQuestion.options.filter((option: any) => {
                    return option.value === userData[lastQuestion.name ? lastQuestion.name : ''];
                });
                nextIndex = selectedOption && selectedOption[0] && selectedOption[0].redirect ? selectedOption[0].redirect : currentSection + 1;
            }
        }
        const history = sectionHistory;
        history.push(currentSection);
        setSectionHistory(history);
        setCurrentSection(index);
        setValidated(false);
        if (document.getElementById("formId")) {
            (document.getElementById("formId") as HTMLElement).scrollIntoView();
        }
        if (nextIndex == -1 || (index && formData.sections && (formData.sections[index].redirect == -1 || (formData.sections.length - 1) == (currentSection + 1)))) {
            setLastStep(true);
        }
    }

    const prevSection = () => {
        const history = sectionHistory;
        setCurrentSection( history[ history.length - 1 ] );        
        history.pop();
        setSectionHistory(history);
        setLastStep(false);
        if (document.getElementById("formId")) {
            (document.getElementById("formId") as HTMLElement).scrollIntoView();
        }
    }

    return (
        <div className='answer-form'>
            <div className="form-content">
                <Row>
                    <Col xs={12} md={12}>
                        {
                            firstStep ? (
                                <div className="introduction">
                                    <div className="pt-3 pb-3">
                                        <h2>{formData.name}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: formData.introduction }}></div>
                                        <Row className="mt-5 align-items-center">
                                            <Col xs={6} md={2}>
                                                <p className="duration-title">Tiempo límite:</p>
                                                <div className="clock-container">
                                                    <div className="overlay">
                                                        <p>{formData.duration}</p>
                                                    </div>
                                                    <img src={Image} className="clock"></img>
                                                </div>
                                            </Col>
                                            <Col xs={6} md={8}>
                                                <h2 className="thanks"><b>¡Muchas gracias!</b><br></br>por tu participación</h2>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row className="justify-content-end">
                                        <Col xs={12} md={4}>
                                            <Button className="mt-5 w-100" variant="primary" onClick={() => setFirstStep(false)}>Comenzar</Button>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <Form id="formId" noValidate validated={validated}>
                                    {
                                        (formData.sections && formData.sections[currentSection] && formData.sections[currentSection].section) && (
                                            <h3 className="mb-3 text-center">{formData.sections[currentSection].section}</h3>
                                        )
                                    }
                                    {
                                        (formData.sections && formData.sections[currentSection] && formData.sections[currentSection].questions[0] && formData.sections[currentSection].questions[0].sectionTitle) && (
                                            <p className="mb-3 text-center">{formData.sections[currentSection].questions[0].sectionTitle}</p>
                                        )
                                    }
                                    {
                                        formData.sections && formData.sections[currentSection] && formData.sections[currentSection].questions.map((item: Question, index: number) => (
                                            <Row className="justify-content-center" key={item.title}>
                                                <Col xs={12} md={12}>
                                                    <Card className="mt-3" id={"card-" + item.name}>
                                                        <Card.Body>
                                                            <Row className="align-items-start">
                                                                <Col key={currentSection + '-' + index} xs={12} md={12}>
                                                                    {
                                                                        (item.type === 'text' || item.type === 'email' || item.type === 'phone' || item.type === 'curp') && (
                                                                            <Input item={item} value={userData[item.name ? item.name : '']} disabled={true}></Input>
                                                                        )
                                                                    }
                                                                    {
                                                                        item.type === 'number' && (
                                                                            <InputNumber item={item} value={userData[item.name ? item.name : '']} disabled={true}></InputNumber>
                                                                        )
                                                                    }
                                                                    {
                                                                        item.type === 'date' && (
                                                                            <InputDate item={item} value={userData[item.name ? item.name : '']} disabled={true}></InputDate>
                                                                        )
                                                                    }
                                                                    {
                                                                        item.type === 'select' && (
                                                                            <Select item={item} value={userData[item.name ? item.name : '']} disabled={true}></Select>
                                                                        )
                                                                    }
                                                                    {
                                                                        item.type === 'radio' && (
                                                                            <Radio item={item} value={userData[item.name ? item.name : '']} disabled={true}></Radio>
                                                                        )
                                                                    }
                                                                    {
                                                                        item.type === 'checkbox' && (
                                                                            <Checkbox item={{
                                                                                name: item.name,
                                                                                title: item.title,
                                                                                options: item.options
                                                                            }} value={userData[item.name ? item.name : '']} disabled={true}></Checkbox>
                                                                        )
                                                                    }
                                                                    {
                                                                        item.type === 'description' && (
                                                                            <Lecture item={item}></Lecture>
                                                                        )
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                    <Row className="justify-content-center">
                                        <Col xs={12} md={8}>
                                            <div className="d-flex justify-content-end mt-3">
                                                {
                                                    currentSection > 0 && (
                                                        <button className="btn btn-primary me-3" type="button" onClick={prevSection}>Regresar</button>
                                                    )
                                                }
                                                {
                                                    (!showLastStep) && (
                                                        <button className="btn btn-primary" type="button" onClick={nextSection}>Siguiente</button>
                                                    )
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }
                    </Col>
                </Row>
            </div>
        </div>
    );
}


export default QuestionnairePreview;
